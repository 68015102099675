import React, { useEffect, useContext } from 'react';
import 'flowbite';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider, UserContext } from './UserContext';
import axios from 'axios';
import './App.css';

import GetDataPage from './features/getPost/getData';
import Header from './component/header/Header';
import Create from './features/createPost/CreatePost';
import Login from "./features/LoginPage/Login";
import Post from './features/post/PostPage';
import Edit from './features/edit/Edit';
import Home from './features/home/Home';
import Pregacoes from './features/pregacoes/Pregacoes';
import Agenda from './features/agenda/Agenda';
import ItensFinanceiro from './features/itens_financeiro/Itens';
import Home2 from './features/home2/home2';
import FinanceiroArrecadacoes from './features/financeiro_arrecadacoes/CreatePost'
import Membros from "./features/lista_membros/Membros";
import NotFound from './404'; // Importe o componente da página 404 (você precisa criá-lo)
import CadastroIgreja from './features/cadastro_igreja/CadastroIgreja';
import Palavra from './features/palavra/Palavra';
import Ministerios from './features/ministerios/Ministerios';
import ResetPasswordForm from './features/recupera_senha/recupera_senha';
import CreatPasswordForm from './features/recupera_senha/resetpagepassword';

function App() {
  return (
    <UserProvider>     
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout ><Home  /></Layout>} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/getdata" element={<Layout><GetDataPage /></Layout>} /> */}
          <Route path="/create" element={<Layout restrictedAdmin><Create /></Layout>} />
          <Route path="/profileigj" element={<Layout restrictedAdmin><CadastroIgreja /></Layout>} />
          <Route path="/fnarrc" element={<Layout restrictedFinanceiro ><FinanceiroArrecadacoes /></Layout>} />
          <Route path="/memlist" element={<Layout restrictedFinanceiro ><Membros /></Layout>} />
          <Route path="/pregacoes" element={<Layout restrictedMidia><Pregacoes /></Layout>} />
          <Route path="/agenda" element={<Layout restrictedMidia><Agenda /></Layout>} />
          <Route path="/palavra" element={<Layout restrictedAdmin><Palavra /></Layout>} />
          <Route path="/ministerios" element={<Layout restrictedAdmin><Ministerios /></Layout>} />
          <Route path="/itfn" element={<Layout restrictedFinanceiro ><ItensFinanceiro /></Layout>} />
          <Route path="/home2" element={<Layout><Home2 /></Layout>} />
          <Route path="/recpasw" element={<ResetPasswordForm/>}/>         
          <Route path="/rscrpss" element={<CreatPasswordForm/>}/>
          {/* <Route path="/post/:id" element={<Layout><Post /></Layout>} />
          <Route path="/post/edit/:id" element={<Layout><Edit /></Layout>} /> */}     
          <Route path="*" element={<NotFound />} />   
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

function Layout({ children, restrictedAdmin, restrictedFinanceiro, restrictedMidia }) {
  const { user, updateUser } = useContext(UserContext);
  const url = `${process.env.REACT_APP_BASE_URL}`;

  const logoutHome = () => {
    // Limpar dados do usuário do localStorage
    localStorage.removeItem("userNicename");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("token");

    // Redirecionar para a página inicial
    //navigate('/');
    window.location.replace("/login");
  };
 

  useEffect(() => {
    // Verifique se o usuário está autenticado
    const checkAuthentication = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const subsiteName = localStorage.getItem("subsiteName");
          const response = await axios.get(`${url}/${subsiteName}/wp-json/wp/v2/users/me`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          // Atualize o contexto do usuário com os dados obtidos
          updateUser(response.data);
          localStorage.setItem("userData", JSON.stringify(response.data));
        } catch (error) {
          setTimeout( function ( ) { alert( "Você será desconectado em 5 segundos" ); }, 5000 );
          logoutHome();
          console.error('Erro ao verificar autenticação:', error);
        // Remover o token e outras informações do localStorage se houver um erro de autenticação
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('subsiteName');
            localStorage.removeItem('userData');
            // Redirecionar o usuário para a tela de login se necessário
            window.location.href = '/login';
          }
        }
      } else {
        // Redirecionar o usuário para a tela de login se o token não estiver presente
        window.location.href = '/login';
      }
    };
    checkAuthentication();
  }, [updateUser]);

  // Adicione uma função para verificar se o usuário é um administrador
  const isUserAdmin = () => {
    return user && user.acf && user.acf.campo_teste === 'administrador';
  };

  const isUserFinanceiro = () => {
    return user && user.acf && user.acf.campo_teste === 'financeiro';
  }

  const isUserMidia = () => {
    return user && user.acf && user.acf.campo_teste === 'midia';
  }

  if ((restrictedAdmin || restrictedFinanceiro || restrictedMidia) && !isUserAdmin()) {
    if ((restrictedFinanceiro || restrictedAdmin) && !isUserFinanceiro()) {
      return <Navigate to="/ntf" />;
    }

    if ((restrictedMidia || restrictedAdmin) && !isUserMidia()) {
      return <Navigate to="/ntf" />;
    }
  }
  //ntf é a pagina de 404!

  return (
    <div>
      <Header  />
      {children}
    </div>
  );
}

export default App;