import React, { useEffect, useState,  useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


function ModalAddPregacoes() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [dataLive, setDataLive] = useState("");
  const [urlLive, setUrlLive] = useState("");  
  const [message, setMessage] = useState({ success: "Cadastrar", message: "" });
  const navigate = useNavigate();
  const navigateRef = useRef(null);

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const userNicename = localStorage.getItem("userNicename");
  const userEmail = localStorage.getItem("userEmail");
  const subsiteName = localStorage.getItem("subsiteName");

  const logout = () => {
    // Limpar dados do usuário do localStorage
    localStorage.removeItem("userNicename");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("token");

    // Redirecionar para a página inicial
    //navigate('/');
    window.location.replace("/");
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
  }, [navigate]);

  const postData = (e) => {
    e.preventDefault(); // Adicione esta linha para evitar o recarregamento da página

    setMessage({ success: "Processando. Aguarde!", message: "Realizando processo. Aguarde..." });

    const formdata = {
      title: title,
      content: content,
      acf: {
        data_pregacao: dataLive,
        url_pregacao: urlLive,        
      },
      status: "publish",
      categoria: "lives",
      //categories: [12],
      tags: [],
    };

    axios
      .post(
        `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=lives`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setMessage({ success: "Sucesso!", message: "Item criado com sucesso!" });
        setContent("");
        setTitle("");        
        setDataLive("");
        setUrlLive("");
        
        // setTimeout(() => {
        //   window.location.reload(); // Atualizar a página após 3 segundos
        // }, 1000);
      })
      .catch((err) => {
        setMessage({ success: "Ops!", message: "Parece que ocorreu algum erro :(" });
      });
     
  };

  //controle modal

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <div>
      {message.message && <p>{message.message}</p>}

      <form
        className="w-full flex flex-col justify-center text-left p-4 dark:bg-white dark:my-5 dark:rounded-md"
        onSubmit={postData}
        ref={navigateRef}
      >
        <h1 className='mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center'>Cadastro de Pregação</h1>  

        <label>Título Pregação</label> 
        <input
          className="w-full mb-4 rounded-md  border-gray-300"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="título live"
        />
        
        <label>Data da Pregação</label>
        <input
          type="date" data-date=""
          data-date-format="DD MMMM YYYY"     
          className="w-full mb-4 rounded-md  border-gray-300"                   
          value={dataLive}
          onChange={(e) => setDataLive(e.target.value)}
          placeholder="data live"
        />        
       
       <label>URL da Pregação</label>
        <input
          type="text"          
          className="w-full mb-4 rounded-md  border-gray-300"           
          value={urlLive}
          onChange={(e) => setUrlLive(e.target.value)}
          placeholder="URL live"
        />
        
        <label>Observação</label>
        <textarea
          rows="3"
          className="w-full mb-4 rounded-md  border-gray-300"
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="observação"
        />
                
        <button
          type="submit"
          class=" w-full m-0  focus:outline-none text-white bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 font-medium text-base py-2.5 mb-4 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
        >
          {message.success}
        </button>
      </form>

      {/* <button onClick={logout}>Logout</button> */}
    </div>
  );
}

export default ModalAddPregacoes;
