import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactSelect from "react-select";
import Select from "react-select";

function CreatePost2() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [dataLancamento, setDataLancamento] = useState("");
  const [dataVencimento, setDataVencimento] = useState("");
  const [valor, setValor] = useState("");
  const [campoTeste, setCampoTeste] = useState("");
  const [message, setMessage] = useState({ success: "Cadastrar", message: "" });
  const navigate = useNavigate();
  const navigateRef = useRef(null);

  const [selectOptions, setSelectOptions] = useState([]); // Estado para guardar as opções do campo select
  const [itens, setItens] = useState([]);

  const [selectedItem, setSelectedItem] = useState("");

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const formatDateForAPI = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const userNicename = localStorage.getItem("userNicename");
  const userEmail = localStorage.getItem("userEmail");
  const subsiteName = localStorage.getItem("subsiteName");

  const logout = () => {
    // Limpar dados do usuário do localStorage
    localStorage.removeItem("userNicename");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("token");

    // Redirecionar para a página inicial
    //navigate('/');
    window.location.replace("/");
  };

  useEffect(() => {
    axios
      .get(
        `${url}/${subsiteName}/wp-json/wp/v2/posts?categora=itens&categoria_item=Organização`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // Extrair os itens relevantes da resposta da API (exemplo: categorias)
        const optionsFromApi = response.data.map((item) => ({
          value: item.title.rendered,
          label: item.title.rendered,
        }));

        setSelectOptions([
          { value: "", label: "Selecione um item" },
          ...optionsFromApi,
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  const postData = (e) => {
    e.preventDefault();

    
    if (!dataVencimento & !valor & selectedItem1) {
      setMessage({
        success: "Ops!",
        message: "Há campos  que não podem estar vazios.",
      });
      return; // Interrompe a execução da função se o campo estiver vazio
  }

    setMessage({
      success: "Processando. Aguarde!",
      message: "Realizando processo. Aguarde...",
    });

    const formdata = {
      title: selectedItem,
      content: content,
      acf: {
        data_lancamento: currentDate,
        data_vencimento: dataVencimento,
        valor_lancamento: valor,
        campo_teste: selectedItem1, //daqui tirei o campoTeste
      },
      status: "publish",
      categoria: "financeiro",
      //categories: [1],
      tags: [],
    };

    axios
      .post(`${url}/${subsiteName}/wp-json/wp/v2/posts`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMessage({
          success: "Sucesso!",
          message: "Item criado com sucesso!",
        });
        setContent("");
        setTitle("");
        setValor("");
        setDataLancamento("");
        setDataVencimento("");
        setCampoTeste("");
        setSelectedItem1("");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((err) => {
        setMessage({
          success: "Ops!",
          message: "Parece que ocorreu algum erro :(",
        });
      });
  };

  //controle modal

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  // Pegar a data atual no formato 'YYYY-MM-DD'
  const currentDate = new Date().toISOString().split("T")[0];

  const SelectComponent = () =>
    selectOptions.length > 0 ? (
      <ReactSelect
        className="w-full mb-4 rounded-md border-gray-300"
        placeholder="Selecione uma categoria"
        options={selectOptions}
        onChange={(selectedOption) =>
          setSelectedItem(selectedOption ? selectedOption.value : null)
        }
        value={
          selectedItem !== null
            ? selectOptions.find((option) => option.value === selectedItem)
            : null
        }
      />
    ) : (
      <p>Carregando opções...</p>
    );
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedItem1, setSelectedItem1] = useState("");

  const options = [
    { value: "Oferta", label: "Oferta" },
    { value: "Dízimo", label: "Dízimo" },
    { value: "Despesa", label: "Despesa" },
  ];

  const SelectComponent2 = () =>
    selectOptions.length > 0 ? (
      <ReactSelect
        className="w-full mb-4 rounded-md border-gray-300"
        placeholder="Selecione uma categoria"
        options={options}
        onChange={(options) => setSelectedItem1(options ? options.value : null)}
        value={
          selectedItem1 !== null
            ? options.find((option) => option.value === selectedItem1)
            : null
        }
      />
    ) : (
      <p>Carregando opções...</p>
    );

  return (
    <div>
      {message.message && <p>{message.message}</p>}

      <form
        className="w-full text-left flex flex-col justify-center  p-4 dark:bg-white dark:my-5 dark:rounded-md"
        onSubmit={postData}
        ref={navigateRef}
      >
        <h1 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
          {" "}
          Cadastro de Lançamento
        </h1>
        {/* <input
          className="w-full mb-4"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="nome lançamento"
        /> */}
        <label>Nome do Lançamento</label>
        <SelectComponent />

        <label>Data Lançamento</label>
        {/* <input
          type="date"
          data-date=""
          data-date-format="DD MMMM YYYY"
          className="w-full mb-4 rounded-md bg-gray-200 border-gray-300"
          value={currentDate}
          readOnly
          onChange={(e) => setDataLancamento(e.target.value)}
          //placeholder="data lançamento"
        /> */}

        <input
          type="date"
          data-date=""
          className="w-full mb-4 rounded-md bg-gray-200 border-gray-300"
          onChange={(e) => setDataLancamento(e.target.value)}
          placeholder="data lançamento"
        />
        {/* ----input liberando data atual para pegar qualquer data  */}

        <div className="flex flex-row">
          <label>Valor do Lançamento</label>

          <div
            className="flex justify-center items-center transititext-primary bg-gray-700  rounded-full p-2 w-5 h-5 ml-1 text-primary transition duration-150 ease-in-out "
            data-te-toggle="tooltip"
            title="O valor deverá ser inserido com ponto. Exemplo: X.xx"
          >
            <span className="text-gray-300 text-sm">?</span>
          </div>
        </div>
        <input
          className="w-full mb-4 rounded-md  border-gray-300"
          id="dataLancamento"
          type="text"
          value={valor}
          onChange={(e) => setValor(e.target.value)}
          placeholder="valor lançamento"
        />

        <label>Data de Vencimento</label>
        <input
          type="date"
          id="dataVencimento"
          data-date-format="DD MMMM YYYY"
          className="w-full mb-4 rounded-md  border-gray-300"
          value={dataVencimento}
          onChange={(e) => setDataVencimento(e.target.value)}
          placeholder="data vencimento"
        />

        <label>Categoria do Lançamento</label>
        {/* <select
          className="w-full mb-4 rounded-md  border-gray-300"
          value={campoTeste}
          onChange={(e) => setCampoTeste(e.target.value)}
        >
          <option value="">Selecione uma opção</option>
          <option value="Oferta">Oferta</option>
          <option value="Dízimo">Dízimo</option>
          <option value="Despesa">Despesa</option>
        </select> */}

        <SelectComponent2 />

        <label>Observação</label>
        <textarea
          rows="3"
          className="w-full mb-4 rounded-md  border-gray-300"
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="observação"
        />

        <button
          type="submit"
          class="w-full m-0  focus:outline-none text-white bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 font-medium text-base py-2.5 mb-4 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
        >
          {message.success}
        </button>
      </form>

      {/* <button onClick={logout}>Logout</button> */}
    </div>
  );
}

export default CreatePost2;
