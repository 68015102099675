import React from "react";
import { useState, useEffect } from 'react';
import moment from "moment";
import { IMaskInput } from "react-imask";


const removePTags = (content) => {
  return content.replace(/<\/?p>/g, "");
};


function EditModalArrecadacoes (

  {
  post,
  editedTitle,
  editedContent,
  editedDataLancamento,
  editedValorLancamento,
  onTitleChange,
  onContentChange,
  onDataLancamentoChange,
  onValorLancamentoChange,
  onSave,
  onCancel

 
  
}) {

  const [formattedDataLancamento, setFormattedDataLancamento] = useState(
    editedDataLancamento ? moment(editedDataLancamento).format("DD-MM-YYYY") : ""
  );

  // Função para interpretar a data digitada pelo usuário e atualizar o estado com o formato "YYYY-MM-DD"
  const handleDataLancamentoChange = (value) => {
    const digitsOnly = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    const formattedDate = moment(digitsOnly, "DDMMYYYY").format("YYYY-MM-DD");
    setFormattedDataLancamento(value);
    onDataLancamentoChange(formattedDate);
  };

  const [localValorLancamento, setLocalValorLancamento] = useState("");

  
  useEffect(() => {
    if (editedValorLancamento) {
      setLocalValorLancamento(parseFloat(editedValorLancamento).toFixed(2));
    }
  }, [editedValorLancamento]);

  const handleValorLancamentoChange = (value) => {
    const digitsOnly = value.replace(/[^0-9.]/g, '');
    const parts = digitsOnly.split('.');
    let formattedValue = digitsOnly;

    if (parts.length === 2 && parts[1].length === 0) {
      formattedValue = parts[0] + '.';
    } else if (parts.length === 2 && parts[1].length > 2) {
      formattedValue = parts[0] + '.' + parts[1].substring(0, 2);
    } else if (parts.length > 2) {
      formattedValue = parts[0] + '.' + parts.slice(1).join('').substring(0, 2);
    }

    setLocalValorLancamento(formattedValue);
    onValorLancamentoChange(formattedValue);
  };
  
  return (
    <div className="w-full text-center flex-col  p-4 fixed inset-0 z-10 flex items-center justify-center  h-screen bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded shadow flex flex-col  items-center">
        <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
          Editar Item
        </h2>

         <label htmlFor="title" className="block mb-2 text-left w-full">
          <span className="font-bold">Nome Membro</span>
        </label>
        <input
          id="title"
          type="text"
          disabled
          className="border border-gray-400 px-4 py-2 rounded w-full mb-4 bg-gray-200"
          value={editedTitle}
          onChange={(e) => onTitleChange(e.target.value)}
        />
        <label htmlFor="title" className="block mb-2 text-left w-full">
          <span className="font-bold">Data Lançamento</span>
        </label>
        {/* <input
          id="data_lancamento"
          type="text"
          className="rounded-md  border-gray-300 px-4 py-2 w-full mb-4 bg-red-900"
          value={formattedDataLancamento}
          onChange={(e) => handleDataLancamentoChange(e.target.value)}
        /> */}
        <IMaskInput
         id="data_lancamento"
         mask= {Date}
         pattern= 'd-`m-`YY'
         className="rounded-md  border border-gray-300 px-4 py-2 w-full mb-4"
         value={formattedDataLancamento}
         onChange={(e) => handleDataLancamentoChange(e.target.value)}
        />

        <div className="flex flex-row justify-start items-start w-full ">
          <label className="mb-2 text-left ">
          <span className="font-bold mb-2">Valor Lançamento</span>
          </label>

          <div
            className="flex justify-center items-center transititext-primary bg-gray-700  rounded-full p-2 w-5 h-5 ml-1 text-primary transition duration-150 ease-in-out "
            data-te-toggle="tooltip"
            title="O valor deverá ser inserido com ponto. Exemplo: X.xx"
          >
            <span className="text-gray-300 text-sm">?</span>
          </div>
        </div>
        <input
          id="valor"
          type="text"
          className="border border-gray-400 px-4 py-2 rounded w-full mb-4"
          value={localValorLancamento}
          onChange={(e) => handleValorLancamentoChange(e.target.value)}
        />

        <label htmlFor="content" className="block mb-2 text-left w-full">
          <span className="font-bold"> Observação</span>
        </label>
        <textarea
          id="content"
          className="rounded-md  border-gray-300 pr-9"
          value={removePTags(editedContent)}
          onChange={(e) => onContentChange(e.target.value)}
        ></textarea>

        <div className="flex justify-end mt-4 w-full">
          <button
            onClick={onSave}
            className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-full"
          >
            Salvar
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded w-full"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditModalArrecadacoes;
