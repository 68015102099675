import React, { useState } from "react";
import axios from "axios";

function ImageModal({ closeModal, setImage, imageId }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const userNicename = localStorage.getItem("userNicename");
  const userEmail = localStorage.getItem("userEmail");
  const subsiteName = localStorage.getItem("subsiteName");

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const handleImageUpload = async (image) => {
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", image);

      const uploadResponse = await axios.post(
        `${url}/${subsiteName}/wp-json/wp/v2/media`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (uploadResponse.data.id) {
        setIsUploading(false);
        closeModal();
        return uploadResponse.data.id; // Retorna o ID da imagem
      } else {
        setIsUploading(false);
        console.error("Erro ao fazer upload da imagem.");
        return null;
      }
    } catch (error) {
      setIsUploading(false);
      console.error("Erro ao fazer upload da imagem:", error);
      return null;
    }
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleImageSubmit = async () => {
    if (selectedImage) {
      const imageId = await handleImageUpload(selectedImage);
      if (imageId !== null) {
        setImage(imageId); // Atualize o ID da imagem no formulário principal
      }
    }
  };

  return (
    <div className="modal">
      <div className="modal-content flex flex-row">
        {/* <h2>Gerenciar Imagem</h2> */}
        <input type="file" accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
        <button onClick={handleImageSubmit} disabled={isUploading} className="bg-gray-400 text-gray-700 px-3 py-2">
          {isUploading ? "Enviando..." : "Enviar nova imagem"}
        </button>
        {/* <button onClick={closeModal} className="bg-orange-400 px-3 py-2">Fechar</button> */}
      </div>
    </div>
  );
}

export default ImageModal;
