import React from "react";
import { useState } from 'react';
import moment from "moment";

const removePTags = (content) => {
  return content.replace(/<\/?p>/g, "");
};


function EditModalMembros (

  {
  post,
  editedTitle,
  editedContent,   
  editedBairro,  
  editedCidade,  
  editedWhatsApp,  
  onTitleChange, 
  onContentChange,
  onBairroChange,
  onCidade,
  onWhatsApp,
  onSave,
  onCancel 
  
}) {

 
  
  return (
    <div className="w-full text-center flex-col  p-4 fixed inset-0 z-10 flex items-center justify-center  h-screen bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded shadow flex flex-col  items-center">
        <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
          Editar Item
        </h2>

        <label htmlFor="title" className="block mb-2 text-left w-full">
          <span className="font-bold">Nome do Membro</span>
        </label>
        <input
          id="title"
          type="text"
          className="rounded-md  border-gray-300 px-4 py-2 w-full mb-4"
          value={editedTitle}
          onChange={(e) => onTitleChange(e.target.value)}
        />

        <label htmlFor="content" className="block mb-2 text-left w-full">
          <span className="font-bold">WhatsApp</span>
        </label>
        <input
          id="content"
          className="rounded-md border  border-gray-300 px-4 py-2  w-full mb-4"
          value={editedWhatsApp}
          onChange={(e) => onWhatsApp(e.target.value)}
        ></input>

        <label htmlFor="content" className="block mb-2 text-left w-full">
          <span className="font-bold">Bairro</span>
        </label>
        <input
          id="content"
          className="rounded-md border  border-gray-300 px-4 py-2  w-full mb-4"
          value={editedBairro}
          onChange={(e) => onBairroChange(e.target.value)}
        ></input>

        <label htmlFor="content" className="block mb-2 text-left w-full">
          <span className="font-bold">Cidade</span>
        </label>
        <input
          id="content"
          className="rounded-md border  border-gray-300 px-4 py-2  w-full mb-4"
          value={editedCidade}
          onChange={(e) => onCidade(e.target.value)}
        ></input>

        <label htmlFor="content" className="block mb-2 text-left w-full">
          <span className="font-bold"> Observação</span>
        </label>
        <textarea
          id="content"
          className="rounded-md  border-gray-300 pr-9"
          value={removePTags(editedContent)}
          onChange={(e) => onContentChange(e.target.value)}
        ></textarea>

        <div className="flex justify-end mt-4 w-full">
          <button
            onClick={onSave}
            className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-full"
          >
            Salvar
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded w-full"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditModalMembros;
