import React, { useEffect, useState } from "react";
import axios from "axios";
import EditModalArrecadacoes from "../EditModal/editModalArrecadacoes";
import moment from "moment";

function FinanceiroArrecadacoes() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [editingPost, setEditingPost] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [editedDataLancamento, setEditedDataLancamento] = useState("");
  const [editedValorLancamento, setEditedValorLancamento] = useState("");
  

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [totalSum, setTotalSum] = useState(0);
  
  const [searchTitle, setSearchTitle] = useState(""); 
  const [currentPageFiltered, setCurrentPageFiltered] = useState(1);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const handleDelete = (postId) => {
    setPostIdToDelete(postId);
    setShowModal(true);
  };

  const subsiteName = localStorage.getItem("subsiteName");
  const confirmDelete = () => {
    setShowModal(false);
    setLoadingDelete(true);

    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",            
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${postIdToDelete}`,
        config
      )
      .then(() => {
        setPosts((prevPosts) =>
          prevPosts.filter((post) => post.id !== postIdToDelete)
        );
        setLoadingDelete(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingDelete(false);
      });
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setEditedTitle(post.title.rendered);
    setEditedContent(post.content.rendered);
    setEditedDataLancamento(post.acf.data_lancamento);
    setEditedValorLancamento(post.acf.valor_lancamento);
    setShowEditModal(true);
  };

  const handleSave = () => {
    setLoadingEdit(true);

    const editedPost = {
      title: editedTitle,
      content: editedContent,
      acf: {
        data_lancamento: moment(editedDataLancamento).format("YYYY-MM-DD"),
        valor_lancamento: editedValorLancamento
      }
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .put(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${editingPost.id}`,
        editedPost,
        config
      )
      .then((res) => {
        setLoadingEdit(false);
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.id === editingPost.id ? res.data : post
          )
        );
        setShowEditModal(false);
      })
      .catch((err) => {
        setLoadingEdit(false);
        console.log(err);
      });
  };

  const removePTags = (content) => {
    return content.replace(/<\/?p>/g, "");
  };


  // Função para formatar a data do formato "DD-MM-YYYY" para "YYYY-MM-DD"
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };


  // Controle de paginação
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;


  useEffect(() => {
    axios
      .get(
        `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=financeiro&per_page=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setPosts(res.data);
        // Extrair categorias únicas dos posts
        const uniqueCategories = [
          ...new Set(res.data.map((post) => post.acf.campo_teste)),
        ];
        setCategories(uniqueCategories);
      })
      .catch((err) => {
        console.log(err);
      });
      

    // Depois que os posts são definidos, aplique os filtros    

    const filtered = posts
      .filter(
        (post) =>
          post.acf.campo_teste === "Dízimo" || post.acf.campo_teste === "Oferta"
      )
      .filter((post) =>
        selectedCategory ? post.acf.campo_teste === selectedCategory : true
      )
      .filter((post) => filterPostsByDate(post))
      .filter((post) =>
        searchTitle
          ? post.title.rendered
              .toLowerCase()
              .includes(searchTitle.toLowerCase())
          : true
      );

    // Defina o estado dos posts filtrados e calcule o somatório total
    setFilteredPosts(filtered);    

  }, [posts, selectedCategory, startDate, endDate,searchTitle]);

  
  // Observe as mudanças aqui
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

   // Função para ir para a próxima página
   const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Função para ir para a página anterior
  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Função para ir para a primeira página
  const firstPage = () => {
    setCurrentPage(1);
  };

  // Função para ir para a última página
  const lastPage = () => {
    setCurrentPage(Math.ceil(filteredPosts.length / postsPerPage));
  };

  // Cálculo do total de páginas
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  // Rótulo para exibir informações sobre a página atual e o total de páginas
  const pageInfo = `Página ${currentPage} de ${totalPages}`;
  /////////fim paginação

 

  // Filtro por data inicial e data final
  const handleStartDateChange = (e) => {
    const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
    setStartDate(formattedDate);
    setSelectedCategory(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    setSelectedCategory(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const filterPostsByDate = (post) => {
    if (!startDate || !endDate) return true;

    const postDate = moment.utc(post.acf.data_lancamento);
    const start = moment.utc(formatDate(startDate), "DD-MM-YYYY");
    const end = moment.utc(formatDate(endDate), "DD-MM-YYYY");

    return postDate.isSameOrAfter(start) && postDate.isSameOrBefore(end);
  };

  const clearData = () => {
    setStartDate("");
    setEndDate("");
    setSearchTitle('');
    //window.location.reload();
  };

  // Fim dos filtros

//posts.lenght
  // if (filteredPosts.length === 0) {
  //   return <div>Sem dados para carregar...</div>;
  // }
  
  return (
    <div>      
      <div class="flex flex-col  justify-center h-full mb-5 sm:flex-col md:flex-col lg:flex-row">        
        <div class=" w-full bg-gray-300 border-solid border-b-4 border-gray-400 flex justify-end sm:flex sm:py-4 sm:w-full sm:justify-center md:py-2 md:w-full md:justify-end lg:w-full dark:border-gray-800">
          <div className="flex mb-6 w-full flex-row justify-center mt-6 sm:mt-auto sm:justify-end md:h-full md:justify-center md:mt-6 lg:mt-0 lg:justify-end ">
            <div className="flex flex-col sm:flex-row justify-center items-center">
              
            <div className="w-full  md:h-full md:flex md:justify-center">
                <fieldset className="border w-full sm:w-auto border-solid border-white p-2 mr-4 flex flex-col justify-center items-start">
                  <legend className="md:text-sm">Filtro Nome</legend>
                  <label className="mr-2 text-sm" htmlFor="searchTitle">
                    Filtrar por nome:
                  </label>
                  <input
                    type="text"
                    id="searchTitle"
                    className="w-full"
                    value={searchTitle}
                    placeholder="digite aqui..."
                    onChange={(e) => setSearchTitle(e.target.value)}
                  />
                </fieldset>
              </div>

              
              <div className="w-full md:h-full md:flex md:justify-center">
                <fieldset className="border border-solid border-white p-2 flex flex-col justify-center items-start">
                  <legend className="md:text-sm">Filtro Data Lançamento</legend>
                  <label className="mr-2  text-sm" htmlFor="startDate">
                    Data Inicial:
                  </label>
                  <input
                    type="date"
                    id="startDate"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="w-full"
                  />
                  <label className="mr-2  text-sm" htmlFor="endDate">
                    Data Final:
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    value={endDate}
                    onChange={handleEndDateChange}
                    className="w-full"
                  />
                  <button
                    className=" bg-orange-500 text-white px-6 py-2 rounded-sm w-full ml-0 mt-2 sm:mt-auto sm:w-auto sm:ml-2 hover:bg-orange-600 md:mt-4 md:ml-0 md:w-full"
                    onClick={clearData}
                  >
                    Limpa Filtros
                  </button>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative overflow-x-auto pb-20 bg-gray-200 dark:bg-gray-600">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Nome Membro
              </th>
              <th scope="col" className="px-6 py-3">
                Data Lançamento
              </th>
              <th scope="col" className="px-6 py-3">
                Valor
              </th>              
              <th scope="col" className="px-6 py-3">
                Categoria
              </th>
              <th scope="col" className="px-6 py-3">
                Observação
              </th>
              <th scope="col" className="px-6 py-3">
                Ações
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            {currentPosts.map((post) => (
                <tr key={post.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div >
                        <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          {post.title.rendered}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      {moment
                        .utc(post.acf.data_lancamento)
                        .format("DD-MM-YYYY")}
                       
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      R$ {post.acf.valor_lancamento.toFixed(2)}
                    </div>
                  </td>
                  
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      {post.acf.campo_teste}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      {removePTags(post.content.rendered)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-4">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-700"
                        onClick={() => handleEdit(post)}
                      >
                        Editar
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 hover:bg-red-700"
                        onClick={() => handleDelete(post.id)}
                      >
                        Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="flex justify-center my-4">
          {/* <div className="flex rounded-md my-4">
            {[...Array(Math.ceil(filteredPosts.length / postsPerPage)).keys()].map(
              (number) => (
                <button
                  key={number}
                  onClick={() => paginate(number + 1)}
                  className={`${
                    currentPage === number + 1
                      ? "bg-blue-500 text-white"
                      : "bg-white text-gray-700"
                  } py-2 px-4 border border-gray-300 focus:outline-none`}
                >
                  {number + 1}
                </button>
              )
            )}
          </div> */}
          <div className="flex rounded-md mt-4 sm:pb-36 md:pb-36 lg:pb-0">
              <button
                onClick={firstPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Primeira
              </button>
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Anterior
              </button>
              <button
                onClick={nextPage}
                disabled={
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Próxima
              </button>
              <button
                onClick={lastPage}
                disabled={
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Última
              </button>
            </div>
          </div>
          <div className="text-center mt-2 text-gray-600 dark:text-white">{pageInfo}</div>        
      </div>
      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
              Confirmação de Exclusão
            </h2>
            <p className="my-6 px-3">Deseja realmente excluir este item?</p>
            <div className="flex justify-end mt-4 w-full">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 mr-2 rounded w-full"
              >
                Excluir
              </button>
              <button
                onClick={cancelDelete}
                className="bg-gray-500 text-white px-4 py-2 rounded w-full"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditModal && (
        <EditModalArrecadacoes
          post={editingPost}
          editedTitle={editedTitle}
          editedContent={editedContent}
          editedDataLancamento={editedDataLancamento}
          editedValorLancamento={editedValorLancamento}
          onTitleChange={setEditedTitle}
          onContentChange={setEditedContent}
          onDataLancamentoChange={setEditedDataLancamento}
          onValorLancamentoChange={setEditedValorLancamento}
          onSave={handleSave}
          onCancel={() => setShowEditModal(false)}
        />
      )}
      {loadingDelete && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p className="ml-2">Excluindo item... Aguarde!</p>
          </div>
        </div>
      )}
      {loadingEdit && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p>Salvando alterações... Aguarde!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default FinanceiroArrecadacoes;



