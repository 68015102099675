import React, { useEffect, useState } from 'react';
import { Link, useNavigate} from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";


import error from "./images/error.png";


function NotFound() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(4);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      navigate('/login');
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Minha Igreja - Oups!</title>
        <meta name="description" content="Sistema para gerenciar igrejas" />
      </Helmet>

      <section className=" bg-gray-300 flex flex-col  h-screen justify-center items-center">
        <img src={error} className=" max-w-md mb-10" />
        <div className=' text-center'>
          <p className=' text-lg'>Parace que você não pode acessar essa página!</p>
          <p className=' text-lg mt-1'>Prapare-se para ser redirecionado e relogar em <span className=' text-xl font-bold'>{countdown}</span> segundos</p>
        </div>        
      </section>
    </div>
  );
}

export default NotFound;