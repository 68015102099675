import React, { useEffect, useState } from "react";
import axios from "axios";
import EditModalMembros from "../EditModal/editModalMembros";

import moment from "moment";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import membros from "../reports/clientes/membros";

export function ListaMembros() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [editingPost, setEditingPost] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [editedBairro, setEditedBairro] = useState("");
  const [editedCidade, setEditedCidade] = useState("");
  const [editedDataAfiliacao, setEditedDataAfiliacao] = useState("");
  const [editedWhatsApp, setEditedWhatsApp] = useState("");

  const [categories, setCategories] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedBairro, setSelectedBairro] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const subsiteName = localStorage.getItem("subsiteName");

  const [perPage, setPerPage] = useState(10); // Estado para armazenar o número de resultados por página

  const handleDelete = (postId) => {
    setPostIdToDelete(postId);
    setShowModal(true);
  };

  const confirmDelete = () => {
    setShowModal(false);
    setLoadingDelete(true);

    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${postIdToDelete}`,
        config
      )
      .then(() => {
        setPosts((prevPosts) =>
          prevPosts.filter((post) => post.id !== postIdToDelete)
        );
        setLoadingDelete(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingDelete(false);
      });
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setEditedTitle(post.title.rendered);
    setEditedContent(post.content.rendered);
    setEditedBairro(post.acf.bairro);
    setEditedCidade(post.acf.cidade);
    setEditedWhatsApp(post.acf.whatsapp);
    setShowEditModal(true);
  };

  const handleSave = () => {
    setLoadingEdit(true);

    const editedPost = {
      title: editedTitle,
      content: editedContent,
      acf: {
        bairro: editedBairro,
        cidade: editedCidade,
        whatsapp: editedWhatsApp,
      },
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .put(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${editingPost.id}`,
        editedPost,
        config
      )
      .then((res) => {
        setLoadingEdit(false);
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.id === editingPost.id ? res.data : post
          )
        );
        setShowEditModal(false);
      })
      .catch((err) => {
        setLoadingEdit(false);
        console.log(err);
      });
  };

  const removePTags = (content) => {
    return content.replace(/<\/?p>/g, "");
  };

  // Controle de paginação
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  // useEffect(() => {
  //   axios.get(
  //       `https://minhaigreja.tec.br/sys/${subsiteName}/wp-json/wp/v2/posts?categories=10&categoria_item=Membro&per_page=${perPage}`
  //     )
  //     .then((res) => {
  //       setPosts(res.data);
  //       //console.log(res.data[0].acf.data_nascimento)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [posts, perPage]);

  useEffect(() => {
    const fetchAllPosts = async () => {
      let allPosts = [];
      let currentPage = 1;
      let totalPages = 1;

      // Loop até obter todas as páginas de registros
      while (currentPage <= totalPages) {
        try {
          const response = await axios.get(
            `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=itens&categoria_item=Membro&per_page=${perPage}&page=${currentPage}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          const { data, headers } = response;
          totalPages = parseInt(headers["x-wp-totalpages"]);
          allPosts = [...allPosts, ...data];
          currentPage++;
        } catch (error) {
          console.error("Erro ao obter registros:", error);
          break; // Encerra o loop em caso de erro
        }
      }

      // Atualize o estado de posts com todos os registros obtidos
      setPosts(allPosts);
    };

    // Chame a função para buscar todos os posts ao montar o componente
    fetchAllPosts();
  }, [subsiteName, perPage]);

  // Filtro por data inicial e data final
  const handleStartDateChange = (e) => {
    const formattedDate = e.target.value;
    setStartDate(formattedDate);
    setSelectedBairro(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    const formattedDate = e.target.value;
    setEndDate(formattedDate);
    setSelectedBairro(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const filterPostsByDate = (post) => {
    if (!startDate || !endDate) return true;

    const postDate = moment
      .utc(post.acf.data_afiliacao, "DD/MM/YYYY")
      .format("DD/MM/YYYY");
    const start = moment.utc(startDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    const end = moment.utc(endDate, "YYYY-MM-DD").format("DD/MM/YYYY");

    return (
      moment(postDate, "DD/MM/YYYY").isSameOrAfter(
        moment(start, "DD/MM/YYYY")
      ) &&
      moment(postDate, "DD/MM/YYYY").isSameOrBefore(moment(end, "DD/MM/YYYY"))
    );
  };

  // Gere as opções para o campo de seleção dos meses
  const monthOptions = Array.from({ length: 12 }, (_, i) => {
    const month = (i + 1).toString().padStart(2, "0");
    return { value: month, label: month };
  });

  // Função de filtro que considera "bairro" e, opcionalmente, "data de filiação"
  const filterPosts = (post) => {
    // Verifique se o post corresponde ao filtro de bairro, se selecionado
    if (selectedBairro && post.acf.bairro !== selectedBairro) {
      return false;
    }

    // Verifique se o post corresponde ao filtro de data de filiação, se definido
    if (startDate && endDate) {
      const postDate = moment.utc(post.acf.data_afiliacao, "DD/MM/YYYY");
      const start = moment.utc(startDate, "YYYY-MM-DD");
      const end = moment.utc(endDate, "YYYY-MM-DD");

      if (
        !postDate.isSameOrAfter(start, "day") ||
        !postDate.isSameOrBefore(end, "day")
      ) {
        return false;
      }
    }

    // Verifique se o post corresponde ao filtro de mês de nascimento, se selecionado
    if (selectedMonth) {
      const birthMonth = moment(post.acf.data_nascimento, "DD/MM/YYYY").format(
        "MM"
      );
      if (birthMonth !== selectedMonth) {
        return false;
      }
    }
    return true;
  };

  //volta para primeira pagina depois de aplicar filtros

  const handleFilterChange = () => {
    setCurrentPage(1); // Redefinir a página para 1 ao aplicar qualquer filtro
  };

  // Observe as mudanças aqui
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const filteredPosts = posts.filter(filterPosts);
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Função para ir para a próxima página
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Função para ir para a página anterior
  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Função para ir para a primeira página
  const firstPage = () => {
    setCurrentPage(1);
  };

  // Função para ir para a última página
  const lastPage = () => {
    setCurrentPage(Math.ceil(filteredPosts.length / postsPerPage));
  };

  // Cálculo do total de páginas
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  // Rótulo para exibir informações sobre a página atual e o total de páginas
  const pageInfo = `Página ${currentPage} de ${totalPages}`;
  /////////fim paginação

  const clearData = () => {
    setStartDate("");
    setEndDate("");
    setSelectedBairro("");
    setSelectedMonth("");
    //window.location.reload();
  };

  // Extrair as opções do campo "acf.bairro" dos dados da API
  const bairros = posts.map((post) => post.acf.bairro);

  // Filtrar as opções para valores únicos
  const uniqueBairros = [...new Set(bairros)];

  // Fim dos filtros
  if (posts.length === 0) {
    return <div>Aguarde...</div>;
  }

  // Função para lidar com a alteração do número de resultados por página
  const handlePerPageChange = (event) => {
    setPerPage(parseInt(event.target.value));
  };

  return (
    <div>
      <div className="bg-gray-200 dark:bg-gray-600">
        <Helmet>
          <title>Minha Igreja - Membros</title>
          <meta name="description" content="Sistema para gerenciar igrejas" />
        </Helmet>
        <div className="flex flex-row justify-end sm:justify-start">
          <button
            onClick={() => membros(currentPosts)}
            className="block text-white ml-2 w-40 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-sm text-sm px-5 py-2.5 mb-4 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            type="button"
          >
            <div className="flex flex-row">
              <svg
                className="w-4 h-4 text-white dark:text-white mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
                />
              </svg>
              Gerar PDF
            </div>
          </button>
          <button
            className=" block bg-orange-500 text-white px-6 rounded-sm ml-2 py-2.5 mb-4 hover:bg-orange-600 "
            onClick={clearData}
          >
            <div className="flex flex-row justify-center items-center">
              <svg
                class="w-4 h-4 text-white dark:text-white mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
                />
              </svg>
              Limpa Filtros
            </div>
          </button>
        </div>

        <div className="bg-gray-400 h-auto py-3 px-4 dark:bg-gray-700 mb-6">
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <div className="">
              <div className="w-full h-full">
                <fieldset className="w-full  border border-solid border-white p-2 mr-4 flex flex-col justify-center items-start h-full">
                  <legend className="text-gray-900 md:text-sm px-2 dark:text-white">
                    Filtro por Bairro
                  </legend>

                  <select
                    className="w-full border border-gray-400 rounded-sm"
                    id="category"
                    value={selectedBairro}
                    onChange={(e) => {
                      setSelectedBairro(e.target.value);
                      handleFilterChange();
                    }}
                  >
                    <option value="">Selecione um bairro</option>
                    {uniqueBairros.map((bairro, index) => (
                      <option key={index} value={bairro}>
                        {bairro}
                      </option>
                    ))}
                  </select>
                </fieldset>
              </div>
            </div>
            <div className="w-full md:h-full md:flex">
              <fieldset className="w-full border border-solid border-white p-2  flex flex-col justify-center items-start h-full">
                <legend className="text-gray-900 md:text-sm px-2 dark:text-white">
                  Filtro por Mês Aniversário
                </legend>
                <select
                  className="w-full border border-gray-400 rounded-sm"
                  value={selectedMonth}
                  onChange={(e) => {
                    setSelectedMonth(e.target.value);
                    handleFilterChange();
                  }}
                >
                  <option value="">Selecione um mês</option>
                  {monthOptions.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </select>
              </fieldset>
            </div>

            <div className="w-full">
              <fieldset className="border border-solid border-white p-2">
                <legend className="text-gray-900 md:text-sm px-2 dark:text-white">
                  Filtro por Data Afiliação
                </legend>
                <div className="flex flex-col md:flex-col xl:flex-row">
                  <div className="w-full">
                    <label
                      className="mr-2  text-sm dark:text-white"
                      htmlFor="startDate"
                    >
                      Data Inicial:
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      className=" w-full  border border-gray-400 rounded-sm xl:w-auto"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="mr-2 text-sm dark:text-white"
                      htmlFor="endDate"
                    >
                      Data Final:
                    </label>
                    <input
                      type="date"
                      id="endDate"
                      className="w-full  border border-gray-400 rounded-sm xl:w-auto"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto pb-20 bg-gray-200 dark:bg-gray-600">
          {/* <div className="w-100 flex justify-end mr-4 my-4">
            <form className="w-100">
              <div className="flex">
                <label
                  for="countries"
                  class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Nº de registros
                </label>
                <div
                  className="flex justify-center items-center transititext-primary bg-gray-700  rounded-full p-2 w-5 h-5 ml-1 text-primary transition duration-150 ease-in-out "
                  data-te-toggle="tooltip"
                  title="Atente-se para que quanto maior o número de registros, maior será o tempo de carregamento da página!"
                >
                  <span className="text-gray-300 text-md font-medium">!</span>
                </div>
              </div>
              <select
                id="perPageSelect"
                value={perPage}
                onChange={handlePerPageChange}
                className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value={3}>3</option>
                <option value={40}>40</option>
                <option value={60}>60</option>
                <option value={100}>100</option>
              </select>
            </form>
          </div> */}
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Nome Membro
                </th>
                <th scope="col" className="px-6 py-3">
                  Data Afiliação
                </th>
                <th scope="col" className="px-6 py-3">
                  WhatsApp
                </th>
                <th scope="col" className="px-6 py-3">
                  Data Nascimento
                </th>
                <th scope="col" className="px-6 py-3">
                  Bairro
                </th>
                <th scope="col" className="px-6 py-3">
                  Obs
                </th>
                <th scope="col" className="px-6 py-3">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
              {currentPosts.length > 0 ? (
                currentPosts.map((post) => (
                  <tr key={post.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                            {post.title.rendered}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                            {post.acf.data_afiliacao}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className=" text-sm text-gray-900 dark:text-gray-200">
                        <Link
                          className="flex items-center"
                          to={`http://wa.me/+55${post.acf.whatsapp.replace(
                            /\D/g,
                            ""
                          )}`}
                          target="_blank"
                        >
                          <svg
                            className="w-4 h-4 mr-1"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z"
                                fill="#0F0F0F"
                              ></path>{" "}
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z"
                                fill="#0F0F0F"
                              ></path>{" "}
                            </g>
                          </svg>
                          {post.acf.whatsapp}
                        </Link>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-200">
                        {post.acf.data_nascimento}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-200">
                        {post.acf.bairro}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-200">
                        {removePTags(post.content.rendered)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-4">
                        <button
                          className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-700"
                          onClick={() => handleEdit(post)}
                        >
                          Editar
                        </button>
                        <button
                          className="bg-red-500 text-white px-4 py-2 hover:bg-red-700"
                          onClick={() => handleDelete(post.id)}
                        >
                          Excluir
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    Nenhum resultado encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="flex justify-center mt-4">
            {/* <div className="flex rounded-md mt-4 sm:pb-36 md:pb-36 lg:pb-0">
              {[
                ...Array(Math.ceil(filteredPosts.length / postsPerPage)).keys(),
              ].map((number) => (
                <button
                  key={number}
                  onClick={() => paginate(number + 1)}
                  className={`${
                    currentPage === number + 1
                      ? "bg-blue-500 text-white"
                      : "bg-white text-gray-700"
                  } py-2 px-4 border border-gray-300 focus:outline-none`}
                >
                  {number + 1}
                </button>
              ))} */}
            <div className="flex rounded-md mt-4 sm:pb-36 md:pb-36 lg:pb-0">
              <button
                onClick={firstPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Primeira
              </button>
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Anterior
              </button>
              <button
                onClick={nextPage}
                disabled={
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Próxima
              </button>
              <button
                onClick={lastPage}
                disabled={
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Última
              </button>
            </div>
          </div>
          <div className="text-center mt-2 text-gray-600 dark:text-white">{pageInfo}</div>
        </div>
        {showModal && (
          <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
              <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
                Confirmação de Exclusão
              </h2>
              <p className="my-6 px-3">Deseja realmente excluir este item?</p>
              <div className="flex justify-end mt-4 w-full">
                <button
                  onClick={confirmDelete}
                  className="bg-red-500 text-white px-4 py-2 mr-2 rounded w-full"
                >
                  Excluir
                </button>
                <button
                  onClick={cancelDelete}
                  className="bg-gray-500 text-white px-4 py-2 rounded w-full"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        )}
        {showEditModal && (
          <EditModalMembros
            post={editingPost}
            editedTitle={editedTitle}
            editedContent={editedContent}
            editedDataAfiliacao={editedDataAfiliacao}
            editedBairro={editedBairro}
            editedCidade={editedCidade}
            editedWhatsApp={editedWhatsApp}
            onTitleChange={setEditedTitle}
            onContentChange={setEditedContent}
            onDataAfiliacaoChange={setEditedDataAfiliacao}
            onBairroChange={setEditedBairro}
            onCidadeChange={setEditedCidade}
            onWhatsApp={setEditedWhatsApp}
            onSave={handleSave}
            onCancel={() => setShowEditModal(false)}
          />
        )}
        {loadingDelete && (
          <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
              <p className="ml-2">Excluindo item... Aguarde!</p>
            </div>
          </div>
        )}
        {loadingEdit && (
          <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
              <p>Salvando alterações... Aguarde!</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ListaMembros;
