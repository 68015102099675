import React, { useEffect, useState,  useRef  } from 'react';
import ReactSelect from 'react-select';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'flowbite';


function ModalAddPregacoes({ onItemAdded, posts, updateTable }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [dataEvento, setDataEvento] = useState("");
  const [localEvento, setLocalEvento] = useState("");  
  const [message, setMessage] = useState({ success: "Cadastrar", message: "" });
  const navigate = useNavigate();
  const navigateRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const url = `${process.env.REACT_APP_BASE_URL}`;
  
  

  const userNicename = localStorage.getItem("userNicename");
  const userEmail = localStorage.getItem("userEmail");
  const subsiteName = localStorage.getItem("subsiteName");

  const logout = () => {
    // Limpar dados do usuário do localStorage
    localStorage.removeItem("userNicename");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("token");

    // Redirecionar para a página inicial
    //navigate('/');
    window.location.replace("/");
  };

  useEffect(() => {
    
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
  }, [navigate]);

  const postData = (e) => {
    e.preventDefault(); // Adicione esta linha para evitar o recarregamento da página

    setMessage({ success: "Processando. Aguarde!", message: "Realizando processo. Aguarde..." });

    const formdata = {
      title: title,
      content: content,
      acf: {
        local_evento: localEvento,        
        data_evento: dataEvento,
        status_evento: selectedItem ? selectedItem.value : null,
      },
      status: "publish",
      categoria: "agenda",
      // categories: [11],
      tags: [],
    };

    axios
      .post(
        `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=agenda`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setMessage({ success: "Sucesso!", message: "Item criado com sucesso!" });        
        setContent("");
        setTitle("");        
        setLocalEvento("");
        setDataEvento("");
        setSelectedItem("");        
        onItemAdded();
        updateTable(); // Atualize a tabela       
      })
      .catch((err) => {
        setMessage({ success: "Ops!", message: "Parece que ocorreu algum erro :(" });
      });
     
  };

  //controle modal


  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const [selectedItem, setSelectedItem] = useState(null);

  const options = [
    { value: 'Publicado', label: 'Publicado' },
    { value: 'Despublicado', label: 'Despublicado' },
  ];

  const handleChange = (selectedOption) => {
    setSelectedItem(selectedOption);
  };
  
  const SelectComponent = () => (
    options.length > 0 ? (
      <ReactSelect
        className="w-full mb-4 rounded-md border-gray-300"
        placeholder="Selecione um status"
        options={options}
        onChange={handleChange}
        value={selectedItem}
      />
    ) : (
      <p>Carregando opções...</p>
    )
  );



  return (
    <div>
      {message.message && <p>{message.message}</p>}

      <form
        className="w-full text-left flex flex-col justify-center p-4 dark:bg-white dark:my-5 dark:rounded-md"
        onSubmit={postData}
        ref={navigateRef}
      >
        <h1 className='mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center'>Cadastro de Evento</h1>   
        
        <label>Título Evento</label>
        <input
          className="w-full mb-4 rounded-md  border-gray-300"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="título do evento"
        />
        
        <label>Data Evento</label>
        <input
          type="date" data-date=""
          data-date-format="DD MMMM YYYY"     
          className="w-full mb-4 rounded-md  border-gray-300"
          value={dataEvento}
          onChange={(e) => setDataEvento(e.target.value)}
          placeholder="data do evento"
        />
        
        <label>Local Evento</label>
        <input
          type="text"          
          className="w-full mb-4 rounded-md  border-gray-300"
          value={localEvento}
          onChange={(e) => setLocalEvento(e.target.value)}
          placeholder="local do evento"
        />
        
        <label>Status</label>
        <SelectComponent/>

        <label>Observação</label>
        <textarea
          rows="3"
          className="w-full mb-4 rounded-md  border-gray-300"
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="observação"
        />        
        
        <button
          type="submit"
          class=" w-full m-0  focus:outline-none text-white bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 font-medium text-base py-2.5 mb-4 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
        >
          {message.success}
        </button>
      </form>

      {/* <button onClick={logout}>Logout</button> */}
    </div>
  );
}

export default ModalAddPregacoes;
