import React, { useState } from "react";
import axios from "axios";
import logo from "../../images/logo-minha-igreja-login.png";
import lgScreen from "../../images/lg-screen.jpg";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";

const ResetPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const params = {
    email: email,
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    

    axios
      .post(
        `${url}/wp-json/bdpwr/v1/reset-password`,
        params,
        
      )
      .then((response) => {
        // Atualizar o estado com os dados da resposta
        setData(response.data);
        
       
      })
      .catch((error) => {
        // Mostrar o erro no console
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
        setEmail("");
        navigate("/rscrpss");
      });
      ;
  };

  return (
    <div>
      <Helmet>
        <title>Minha Igreja - Login Usuário</title>
        <meta name="description" content="Sistema para gerenciar igrejas" />
      </Helmet>

      <section className="flex flex-col md:flex-row h-screen items-center">
        <div className="bg-blue-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
          <img src={lgScreen} className="w-full h-full object-cover" />
        </div>

        <div
          className="bg-yellow-100 w-full md:max-w-md lg:max-w-full md:mx-auto  md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
          flex items-center justify-center"
        >
          <div className="w-full h-100">
            <form
              className=" text-center flex flex-col justify-center items-center "
              onSubmit={handleSubmit}
            >
              <div className="bg-cyan-900 border-b-8 border-cyan-950 rounded-3xl flex flex-col justify-center items-center p-4">
                <img className=" w-20 mb-6" src={logo} alt="logo" />
                <h1 className="text-white text-2xl font-bold font-rubik uppercase mb-6 px-4">
                  Recuperação de Senha
                </h1>

                <input
                  className="w-60 mb-6 border-solid border-1 border-gray-300 rounded-md"
                  type="email"
                  placeholder="Endereço de e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <button
                  className="w-60 m-0 focus:outline-none text-white bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 font-medium rounded-lg text-lg py-2.5  my-4 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  type="submit"
                >
                  Enviar
                </button>

                {loading ? (
                  // md:inset-0 h-[calc(100%-1rem)] max-
                  <div className="fixed flex justify-center items-center top-0 left-0 right-0  p-4 overflow-x-hidden overflow-y-auto h-screen bg-gray-800 bg-opacity-50">
                    <div className="relative w-2/12  max-h-full">
                      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col justify-center items-center p-4">
                        <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900 mr-2"></div>
                        <p className="mt-6">Enviando solicitação para seu email. Aguarde!</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
               
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPasswordForm;
