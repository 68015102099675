import React, { useEffect, useState,  useRef  } from 'react';
import ReactSelect from 'react-select';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'flowbite';


function ModalAddMinisterios({ onItemAdded, posts, updateTable }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");  
  const [departamento, setDepartamento] = useState("");  
  const [lider, setLider] = useState("");  
  const [integrantes, setIntegrantes] = useState("");  

  const [message, setMessage] = useState({ success: "Cadastrar", message: "" });
  const navigate = useNavigate();
  const navigateRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectOptions, setSelectOptions] = useState([]); // Estado para guardar as opções do campo select
  const [selectOptions2, setSelectOptions2] = useState([]); // Estado para guardar as opções do campo select
  const [itens, setItens] = useState([]);

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItem2, setSelectedItem2] = useState("");

  const url = `${process.env.REACT_APP_BASE_URL}`; 
  

  const userNicename = localStorage.getItem("userNicename");
  const userEmail = localStorage.getItem("userEmail");
  const subsiteName = localStorage.getItem("subsiteName");

  const logout = () => {
    // Limpar dados do usuário do localStorage
    localStorage.removeItem("userNicename");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("token");

    // Redirecionar para a página inicial
    //navigate('/');
    window.location.replace("/");
  };

  useEffect(() => {
    
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
    const token = localStorage.getItem('token');
  }, [navigate]);

  const postData = (e) => {
    e.preventDefault(); // Adicione esta linha para evitar o recarregamento da página

    setMessage({ success: "Processando. Aguarde!", message: "Realizando processo. Aguarde..." });

    console.log('selectedItem2:', selectedItem2)

    const integrantesSerialized = selectedItem2.map(item => item.value).join(', ');


    const formdata = {
      title: title,
      content: content,
      acf: {
        departamento: departamento,
        lider: selectedItem ? selectedItem.value : null,
        integrantes: integrantesSerialized,
      },
      status: "publish",
      categoria: "ministerio",      
      tags: [],
    };

    axios
      .post(
        `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=ministerio`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setMessage({ success: "Sucesso!", message: "Item criado com sucesso!" });        
        setContent("");
        setTitle("");        
        setDepartamento("");        
        setLider("");        
        setSelectedItem(null);  
        setSelectedItem2([]);      
        onItemAdded();
        updateTable(); // Atualize a tabela       
      })
      .catch((err) => {
        setMessage({ success: "Ops!", message: "Parece que ocorreu algum erro :(" });
      });
     
  };

  //-----------controle campos selects

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token não encontrado');
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=itens&categoria_item=Membro&per_page=500`, 
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`
            }
          }
        );

        const optionsFromApi = response.data.map((item) => ({
          value: item.title.rendered,
          label: item.title.rendered,
        }));

        setSelectOptions([{ value: "", label: "Selecione um item" }, ...optionsFromApi]);
        setSelectOptions2([{ value: "", label: "Selecione um item" }, ...optionsFromApi]);
      } catch (error) {
        console.error('Erro na requisição', error);
      }
    };

    fetchData();
  }, []);

  
  const SelectComponent1 = () =>
    selectOptions.length > 0 ? (
      <ReactSelect
        className="w-full mb-4 rounded-md border-gray-300"
        placeholder="Selecione uma categoria"
        options={selectOptions}
        onChange={(selectedOption) => setSelectedItem(selectedOption)}
        value={selectedItem}
      />
    ) : (
      <p>Carregando opções...</p>
    );

//----------------------------    
  const options = [
    { value: 'pastoral', label: 'Pastoral' },
    { value: 'financas', label: 'Finanças' },
    { value: 'infantil', label: 'Infantil' },
    { value: 'homens', label: 'Homens' },
    { value: 'mulheres', label: 'Mulheres' },
    { value: 'jovens', label: 'Jovens' },
    { value: 'familia', label: 'Família' },
    { value: 'oracao', label: 'Oração' },
    { value: 'intersecao', label: 'Interseção' },
    { value: 'templo', label: 'Templo' },
  ];

  const handleChangeDepartamento = (selectedOption) => {
    setDepartamento(selectedOption ? selectedOption.value : "");
  };

  const SelectComponent = () => (
    options.length > 0 ? (
      <ReactSelect
        className="w-full mb-4 rounded-md border-gray-300"
        placeholder="Selecione um status"
        options={options}
        onChange={handleChangeDepartamento}
        value={options.find(option => option.value === departamento)}
      />
    ) : (
      <p>Carregando opções...</p>
    )
  );

  const SelectComponent3 = () =>
    selectOptions2.length > 0 ? (
      <ReactSelect
      className="w-full mb-4 rounded-md border-gray-300"
      placeholder="Selecione os integrantes"
      options={selectOptions2}
      isMulti
      onChange={(selectedOptions2) =>
        setSelectedItem2(selectedOptions2 ? selectedOptions2 : [])
      }
      value={selectedItem2}
    />
  ) : (
    <p>Carregando opções...</p>
  );

return (
  <div>
    {message.message && <p>{message.message}</p>}

    <form
      className="w-full text-left flex flex-col justify-center p-4 dark:bg-white dark:my-5 dark:rounded-md"
      onSubmit={postData}
      ref={navigateRef}
    >
      <h1 className='mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center'>Cadastro de Ministério</h1>

      <label>Título Ministério</label>
      <input
        className="w-full mb-4 rounded-md border-gray-300"
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Título do ministério"
      />

      <label>Líder Ministério</label>
      <SelectComponent1/>

      <label>Departamento Ministerial</label>
      <SelectComponent/>

      <label>Integrantes</label>
      <SelectComponent3/>

      <label>Observação</label>
      <textarea
        rows="3"
        className="w-full mb-4 rounded-md border-gray-300"
        type="text"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Observação"
      />

      <button
        type="submit"
        className="w-full m-0 focus:outline-none text-white bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 font-medium text-base py-2.5 mb-4 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
      >
        {message.success}
      </button>
    </form>
  </div>
);
}

export default ModalAddMinisterios;

