import React, { useEffect, useState } from "react";
import axios from "axios";
import EditModalAgendaPrecacoes from "../EditModal/editModalAgendaPrecacoes";
import moment from 'moment';
import { Link } from "react-router-dom";
import Helmet from 'react-helmet'

function ListaLives() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [editingPost, setEditingPost] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [editedDataPregacao, setEditedDataPregacao] = useState("");
  const [editedUrlPregacao, setEditedUrlPregacao] = useState("");

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const handleDelete = (postId) => {
    setPostIdToDelete(postId);
    setShowModal(true);
  };

  const subsiteName = localStorage.getItem("subsiteName");
  const confirmDelete = () => {
    setShowModal(false);
    setLoadingDelete(true);

    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${postIdToDelete}`,
        config
      )
      .then(() => {
        setPosts((prevPosts) =>
          prevPosts.filter((post) => post.id !== postIdToDelete)
        );
        setLoadingDelete(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingDelete(false);
      });
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setEditedTitle(post.title.rendered);
    setEditedContent(post.content.rendered);
    setEditedDataPregacao(post.acf.data_pregacao);
    setEditedUrlPregacao(post.acf.url_pregacao);
    setShowEditModal(true);
  };

  const handleSave = () => {
    setLoadingEdit(true);

    const editedPost = {
      title: editedTitle,
      content: editedContent,
      acf: {
        data_pregacao: moment(editedDataPregacao).format("YYYY-MM-DD"),
        url_pregacao: editedUrlPregacao,
      },
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .put(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${editingPost.id}`,
        editedPost,
        config
      )
      .then((res) => {
        setLoadingEdit(false);
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.id === editingPost.id ? res.data : post
          )
        );
        setShowEditModal(false);
      })
      .catch((err) => {
        setLoadingEdit(false);
        console.log(err);
      });
  };

  const removePTags = (content) => {
    return content.replace(/<\/?p>/g, "");
  };

  // Controle de paginação
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  useEffect(() => {
    axios
      .get(
        `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=lives`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [posts]);

  // Observe as mudanças aqui
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Função para ir para a próxima página
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Função para ir para a página anterior
  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Função para ir para a primeira página
  const firstPage = () => {
    setCurrentPage(1);
  };

  // Função para ir para a última página
  const lastPage = () => {
    setCurrentPage(Math.ceil(posts.length / postsPerPage));
  };

  // Cálculo do total de páginas
  const totalPages = Math.ceil(posts.length / postsPerPage);

  // Rótulo para exibir informações sobre a página atual e o total de páginas
  const pageInfo = `Página ${currentPage} de ${totalPages}`;
  /////////fim paginação

  // Filtro por data inicial e data final
  const handleStartDateChange = (e) => {
    const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
    setStartDate(formattedDate);
    setSelectedCategory(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    setSelectedCategory(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const filterPostsByDate = (post) => {
    if (!startDate || !endDate) return true;

    const postDate = moment
      .utc(post.acf.data_pregacao, "DD-MM-YYYY")
      .startOf("day");
    const start = moment.utc(startDate, "DD-MM-YYYY").startOf("day");
    const end = moment.utc(endDate, "DD-MM-YYYY").startOf("day");

    return postDate.isSameOrAfter(start) && postDate.isSameOrBefore(end);
  };

  const clearData = () => {
    setStartDate("");
    setEndDate("");
    //window.location.reload();
  };

  // Fim dos filtros

  // if (posts.length === 0) {
  //   return <div>Sem dados para carregar...</div>;
  // };

  return (
    <div>
      <Helmet>
        <title>Minha Igreja - Pregações</title>
        <meta name="description" content="Sistema para gerenciar igrejas" />
      </Helmet>
      <div className="bg-gray-300 border-solid justify-center border-b-4 border-gray-400 flex mb-6 sm:justify-end dark:border-gray-800">
        <div className="py-2">
          <fieldset className="border border-solid border-white p-2 mr-4 flex flex-col justify-center items-start">
            <legend className="md:text-sm">Filtro Data Pregações</legend>
            <label className="mr-2 text-sm" htmlFor="startDate">
              Data Inicial:
            </label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={handleStartDateChange}
            />
            <label className="mr-2 text-sm" htmlFor="endDate">
              Data Final:
            </label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={handleEndDateChange}
            />
            <button
              className=" bg-orange-500 text-white px-6 py-2 rounded-sm w-full ml-0 mt-2 sm:mt-auto sm:w-auto sm:ml-2 hover:bg-orange-600 md:mt-4 md:ml-0 md:w-full"
              onClick={clearData}
            >
              Limpa Filtros
            </button>
          </fieldset>
        </div>
      </div>
      <div className="relative overflow-x-auto pb-20 bg-gray-200 dark:bg-gray-600">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Título Pregação
              </th>
              <th scope="col" className="px-6 py-3">
                Data Pregação
              </th>
              <th scope="col" className="px-6 py-3">
                URL Pregação
              </th>
              <th scope="col" className="px-6 py-3">
                Obs
              </th>
              <th scope="col" className="px-6 py-3">
                Ações
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            {posts
              .filter(filterPostsByDate)
              .slice(indexOfFirstPost, indexOfLastPost)

              .map((post) => (
                <tr key={post.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          {post.title.rendered}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      {moment.utc(post.acf.data_pregacao).format("DD-MM-YYYY")}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      <Link
                        to={post.acf.url_pregacao}
                        target="_blank"
                        className="flex items-center"
                      >
                        <svg
                          className="w-[14px] h-[14px] mr-2 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 18 18"
                        >
                          <path d="M17 0h-5.768a1 1 0 1 0 0 2h3.354L8.4 8.182A1.003 1.003 0 1 0 9.818 9.6L16 3.414v3.354a1 1 0 0 0 2 0V1a1 1 0 0 0-1-1Z" />
                          <path d="m14.258 7.985-3.025 3.025A3 3 0 1 1 6.99 6.768l3.026-3.026A3.01 3.01 0 0 1 8.411 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V9.589a3.011 3.011 0 0 1-1.742-1.604Z" />
                        </svg>

                        {post.acf.url_pregacao}
                      </Link>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      {removePTags(post.content.rendered)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-4">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-700"
                        onClick={() => handleEdit(post)}
                      >
                        Editar
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 hover:bg-red-700"
                        onClick={() => handleDelete(post.id)}
                      >
                        Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="flex justify-center mt-4">
        <div className="flex rounded-md mt-4 sm:pb-36 md:pb-36 lg:pb-0">
              <button
                onClick={firstPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Primeira
              </button>
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Anterior
              </button>
              <button
                onClick={nextPage}
                disabled={
                  currentPage === Math.ceil(posts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(posts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Próxima
              </button>
              <button
                onClick={lastPage}
                disabled={
                  currentPage === Math.ceil(posts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(posts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Última
              </button>
            </div>
          </div>
          <div className="text-center mt-2 text-gray-600 dark:text-white">{pageInfo}</div>
      </div>
      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
              Confirmação de Exclusão
            </h2>
            <p className="my-6 px-3">Deseja realmente excluir este item?</p>
            <div className="flex justify-end mt-4 w-full">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 mr-2 rounded w-full"
              >
                Excluir
              </button>
              <button
                onClick={cancelDelete}
                className="bg-gray-500 text-white px-4 py-2 rounded w-full"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditModal && (
        <EditModalAgendaPrecacoes
          post={editingPost}
          editedTitle={editedTitle}
          editedContent={editedContent}
          editedDataPregacao={editedDataPregacao}
          editedUrlPregacao={editedUrlPregacao}
          onTitleChange={setEditedTitle}
          onContentChange={setEditedContent}
          onDataPregacaoChange={setEditedDataPregacao}
          onUrlChange={setEditedUrlPregacao}
          onSave={handleSave}
          onCancel={() => setShowEditModal(false)}
        />
      )}
      {loadingDelete && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p className="ml-2">Excluindo item... Aguarde!</p>
          </div>
        </div>
      )}
      {loadingEdit && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p>Salvando alterações... Aguarde!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListaLives;