import React, { useEffect, useState } from "react";
import axios from "axios";
import EditModal from "../EditModal/editModal";
import moment from "moment";
import clientesPDF from "../reports/clientes/clientes";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

function Financeiro() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [editingPost, setEditingPost] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [editedDataVencimento, setEditedDataVencimento] = useState("");
  const [editedValorLancamento, setEditedValorLancamento] = useState("");

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateVenci, setStartDateVenci] = useState("");
  const [endDateVenci, setEndDateVenci] = useState("");

  //const [totalSum, setTotalSum] = useState(0);

  const [totalOfertaDizimo, setTotalOfertaDizimo] = useState(0);
  const [totalDespesa, setTotalDespesa] = useState(0);

  const [searchTitle, setSearchTitle] = useState("");
  // const [currentPageFiltered, setCurrentPageFiltered] = useState(1);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [chartData, setChartData] = useState([]); //Estado grafico DD/MM/Y
  const [chartDataMouth, setChartDataMouth] = useState([]); //Estado grafico MM/Y

  const [perPage, setPerPage] = useState(10); // Estado para armazenar o número de resultados por página

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const handleDelete = (postId) => {
    setPostIdToDelete(postId);
    setShowModal(true);
  };

  const subsiteName = localStorage.getItem("subsiteName");
  const confirmDelete = () => {
    setShowModal(false);
    setLoadingDelete(true);

    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${postIdToDelete}`,
        config
      )
      .then(() => {
        setPosts((prevPosts) =>
          prevPosts.filter((post) => post.id !== postIdToDelete)
        );
        setLoadingDelete(false);
        
      })
      .catch((err) => {
        console.log(err);
        setLoadingDelete(false);
      });
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setEditedTitle(post.title.rendered);
    setEditedContent(post.content.rendered);
    setEditedDataVencimento(post.acf.data_vencimento);
    setEditedValorLancamento(post.acf.valor_lancamento);
    setShowEditModal(true);
  };

  const handleSave = () => {
    setLoadingEdit(true);

    const editedPost = {
      title: editedTitle,
      content: editedContent,
      acf: {
        data_vencimento: moment(editedDataVencimento).format("YYYY-MM-DD"),
        valor_lancamento: editedValorLancamento,
      },
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .put(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${editingPost.id}`,
        editedPost,
        config
      )
      .then((res) => {
        setLoadingEdit(false);
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.id === editingPost.id ? res.data : post
          )
        );
        setShowEditModal(false);
      })
      .catch((err) => {
        setLoadingEdit(false);
        console.log(err);
      });
  };

  const removePTags = (content) => {
    return content.replace(/<\/?p>/g, "");
  };

  // Função para formatar a data do formato "DD-MM-YYYY" para "YYYY-MM-DD"
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  // Controle de paginação
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  // useEffect(() => {
  //   axios
  //   .get(`https://minhaigreja.tec.br/sys/${subsiteName}/wp-json/wp/v2/posts?categories=1&per_page=${perPage}`)
  //     .then((res) => {
  //       setPosts(res.data);

  //       //grafico
  //       const data = res.data;

  //       const filteredData = data.filter((item) =>
  //         ["Dízimo", "Oferta", "Despesa"].includes(item.acf.campo_teste)
  //       );
  //       const dateMap = {};

  //       filteredData.forEach((item) => {
  //         const date = moment(item.acf.data_lancamento).utc().startOf("day");

  //         const dateString = date.format("D/M");

  //         if (!dateMap[dateString]) {
  //           dateMap[dateString] = {};
  //         }

  //         if (!dateMap[dateString][item.acf.campo_teste]) {
  //           dateMap[dateString][item.acf.campo_teste] = 0;
  //         }

  //         dateMap[dateString][item.acf.campo_teste] +=
  //           item.acf.valor_lancamento;
  //       });

  //       const formattedChartData = Object.keys(dateMap).map((dateString) => ({
  //         date: dateString,
  //         ...dateMap[dateString],
  //       }));

  //       setChartData(formattedChartData);
  //       //fim grafico

  //       // Extrair categorias únicas dos posts
  //       const uniqueCategories = [
  //         ...new Set(res.data.map((post) => post.acf.campo_teste)),
  //       ];
  //       setCategories(uniqueCategories);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   // Depois que os posts são definidos, aplique os filtros

  //   const filtered = posts
  //     .filter((post) =>
  //       selectedCategory ? post.acf.campo_teste === selectedCategory : true
  //     )
  //     .filter((post) => filterPostsByDate(post))
  //     .filter((post) => filterPostsByDateVenci(post))
  //     .filter((post) =>
  //       searchTitle
  //         ? post.title.rendered
  //             .toLowerCase()
  //             .includes(searchTitle.toLowerCase())
  //         : true
  //     );

  //   // Defina o estado dos posts filtrados e calcule o somatório total
  //   setFilteredPosts(filtered);

  //   //somatorios individuais
  //   const calculateTotals = (data) => {
  //     const ofertaDizimoItems = data.filter(
  //       (post) =>
  //         post.acf.campo_teste === "Dízimo" || post.acf.campo_teste === "Oferta"
  //     );

  //     const despesaItems = data.filter(
  //       (post) => post.acf.campo_teste === "Despesa"
  //     );

  //     const totalOfertaDizimoSum = ofertaDizimoItems.reduce(
  //       (acc, post) => acc + parseFloat(post.acf.valor_lancamento),
  //       0
  //     );
  //     const totalDespesaSum = despesaItems.reduce(
  //       (acc, post) => acc + parseFloat(post.acf.valor_lancamento),
  //       0
  //     );

  //     return {
  //       totalOfertaDizimo: totalOfertaDizimoSum,
  //       totalDespesa: totalDespesaSum,
  //     };
  //   };

  //   const { totalOfertaDizimo, totalDespesa } = calculateTotals(filtered);
  //   setTotalOfertaDizimo(totalOfertaDizimo);
  //   setTotalDespesa(totalDespesa);

  // }, [
  //   posts,
  //   selectedCategory,
  //   startDate,
  //   endDate,
  //   startDateVenci,
  //   endDateVenci,
  //   searchTitle,
  //   totalOfertaDizimo,
  //   perPage
  // ]);

  //grafico por mes

  // const calculateMonthlyTotalsAndChartDataMounth = (data) => {
  //   const filteredData = data.filter((item) =>
  //     ["Dízimo", "Oferta", "Despesa"].includes(item.acf.campo_teste)
  //   );

  //   const monthMap = {};

  //   filteredData.forEach((item) => {
  //     const date = moment(item.acf.data_lancamento).utc().startOf("month");
  //     const monthString = date.format("MM/YYYY");

  //     if (!monthMap[monthString]) {
  //       monthMap[monthString] = {};
  //       monthMap[monthString]["Dízimo"] = 0;
  //       monthMap[monthString]["Oferta"] = 0;
  //       monthMap[monthString]["Despesa"] = 0;
  //     }

  //     monthMap[monthString][item.acf.campo_teste] += parseFloat(item.acf.valor_lancamento);
  //   });

  //   const formattedChartData = Object.keys(monthMap).map((monthString) => ({
  //     month: monthString,
  //     ...monthMap[monthString],
  //   }));

  //   return formattedChartData;
  // };

  const calculateMonthlyTotalsAndChartDataMounth = (data) => {
    const filteredData = data.filter((item) =>
      ["Dízimo", "Oferta", "Despesa"].includes(item.acf.campo_teste)
    );

    const currentYear = new Date().getFullYear(); // Obter o ano atual

    const monthMap = {};

    filteredData.forEach((item) => {
      const date = moment(item.acf.data_lancamento).utc();

      // Verificar se o dado está no ano atual
      if (date.year() === currentYear) {
        const monthString = date.startOf("month").format("MM/YYYY");

        if (!monthMap[monthString]) {
          monthMap[monthString] = {};
          monthMap[monthString]["Dízimo"] = 0;
          monthMap[monthString]["Oferta"] = 0;
          monthMap[monthString]["Despesa"] = 0;
        }

        monthMap[monthString][item.acf.campo_teste] += parseFloat(
          item.acf.valor_lancamento
        );
      }
    });

    const formattedChartData = Object.keys(monthMap).map((monthString) => ({
      month: monthString,
      ...monthMap[monthString],
    }));

    return formattedChartData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fazer a primeira chamada para obter o número total de registros
        const response = await axios.get(
          `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=financeiro`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const totalCount = response.headers["x-wp-total"]; // Obter o número total de registros
        const totalPages = Math.ceil(totalCount / perPage); // Calcular o número total de páginas

        // Inicializar uma lista para armazenar todos os registros
        const allPosts = [];

        // Fazer chamadas para cada página e armazenar os resultados
        for (let page = 1; page <= totalPages; page++) {
          const pageResponse = await axios.get(
            `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=financeiro&page=${page}&per_page=${perPage}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          const pageData = pageResponse.data;
          allPosts.push(...pageData);
        }

        // Extrair categorias únicas dos posts
        const uniqueCategories = [
          ...new Set(allPosts.map((post) => post.acf.campo_teste)),
        ];
        setCategories(uniqueCategories);

        // Aplicar filtros nos posts
        const filtered = allPosts
          .filter((post) =>
            selectedCategory ? post.acf.campo_teste === selectedCategory : true
          )
          .filter((post) => filterPostsByDate(post))
          .filter((post) => filterPostsByDateVenci(post))
          .filter((post) =>
            searchTitle
              ? post.title.rendered
                  .toLowerCase()
                  .includes(searchTitle.toLowerCase())
              : true
          );

        // Definir o estado dos posts filtrados
        setFilteredPosts(filtered);

        // Calcular os totais e outros dados necessários
        const { totalOfertaDizimo, totalDespesa, formattedChartData } =
          calculateTotalsAndChartData(filtered);
          setTotalOfertaDizimo(totalOfertaDizimo);
          setTotalDespesa(totalDespesa);
          setChartData(formattedChartData);

        //-----------

        // Calcular os totais e outros dados necessários
        const monthlyData = calculateMonthlyTotalsAndChartDataMounth(filtered);
        setChartDataMouth(monthlyData);
      } catch (error) {
        console.error("Erro ao obter registros:", error);
      }
    };

    // Chamar a função para buscar todos os registros quando as dependências mudarem
    fetchData();
  }, [
    subsiteName,
    perPage,
    selectedCategory,
    startDate,
    endDate,
    startDateVenci,
    endDateVenci,
    searchTitle,
    filteredPosts,  //inserir isso pra atualizar a tabela depois de add um registro
  ]);

  //grafico por dia
  // const calculateTotalsAndChartData = (data) => {
  //   const filteredData = data.filter((item) =>
  //     ["Dízimo", "Oferta", "Despesa"].includes(item.acf.campo_teste)
  //   );

  //   const dateMap = {};

  //   filteredData.forEach((item) => {
  //     const date = moment(item.acf.data_lancamento).utc().startOf("day");
  //     const dateString = date.format("DD/MM/Y");

  //     if (!dateMap[dateString]) {
  //       dateMap[dateString] = {};
  //     }

  //     if (!dateMap[dateString][item.acf.campo_teste]) {
  //       dateMap[dateString][item.acf.campo_teste] = 0;
  //     }

  //     dateMap[dateString][item.acf.campo_teste] += parseFloat(
  //       item.acf.valor_lancamento
  //     );
  //   });

  //   const formattedChartData = Object.keys(dateMap).map((dateString) => ({
  //     date: dateString,
  //     ...dateMap[dateString],
  //   }));

  //   //fim grafico

  //   // Calcular os totais
  //   const ofertaDizimoItems = filteredData.filter(
  //     (post) =>
  //       post.acf.campo_teste === "Dízimo" || post.acf.campo_teste === "Oferta"
  //   );

  //   const despesaItems = filteredData.filter(
  //     (post) => post.acf.campo_teste === "Despesa"
  //   );

  //   const totalOfertaDizimoSum = ofertaDizimoItems.reduce(
  //     (acc, post) => acc + parseFloat(post.acf.valor_lancamento),
  //     0
  //   );
  //   const totalDespesaSum = despesaItems.reduce(
  //     (acc, post) => acc + parseFloat(post.acf.valor_lancamento),
  //     0
  //   );

  //   return {
  //     totalOfertaDizimo: totalOfertaDizimoSum,
  //     totalDespesa: totalDespesaSum,
  //     formattedChartData: formattedChartData,
  //   };
  // };


  //dados ultimos 30 lançamentos -20-05-24
  const calculateTotalsAndChartData = (data) => {
    // Filtrar os dados relevantes
    const filteredData = data.filter((item) =>
      ["Dízimo", "Oferta", "Despesa"].includes(item.acf.campo_teste)
    );
  
    // Ordenar os dados por data de lançamento em ordem decrescente
    const sortedData = filteredData.sort((a, b) => 
      new Date(b.acf.data_lancamento) - new Date(a.acf.data_lancamento)
    );
  
    // Pegar apenas os últimos 30 lançamentos
    const limitedData = sortedData.slice(0, 30);
  
    const dateMap = {};
  
    limitedData.forEach((item) => {
      const date = moment(item.acf.data_lancamento).utc().startOf("day");
      const dateString = date.format("DD/MM/Y");
  
      if (!dateMap[dateString]) {
        dateMap[dateString] = {};
      }
  
      if (!dateMap[dateString][item.acf.campo_teste]) {
        dateMap[dateString][item.acf.campo_teste] = 0;
      }
  
      dateMap[dateString][item.acf.campo_teste] += parseFloat(item.acf.valor_lancamento);
    });
  
    const formattedChartData = Object.keys(dateMap).map((dateString) => ({
      date: dateString,
      ...dateMap[dateString],
    }));
  
    // Calcular os totais
    const ofertaDizimoItems = limitedData.filter(
      (post) => post.acf.campo_teste === "Dízimo" || post.acf.campo_teste === "Oferta"
    );
  
    const despesaItems = limitedData.filter(
      (post) => post.acf.campo_teste === "Despesa"
    );
  
    const totalOfertaDizimoSum = ofertaDizimoItems.reduce(
      (acc, post) => acc + parseFloat(post.acf.valor_lancamento),
      0
    );
    const totalDespesaSum = despesaItems.reduce(
      (acc, post) => acc + parseFloat(post.acf.valor_lancamento),
      0
    );
  
    return {
      totalOfertaDizimo: totalOfertaDizimoSum,
      totalDespesa: totalDespesaSum,
      formattedChartData: formattedChartData,
    };
  };
  

  // Observe as mudanças aqui
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Função para ir para a próxima página
  const nextPage = () => {
    const totalPages = Math.ceil(filteredPosts.length / postsPerPage);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Função para ir para a página anterior
  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Função para ir para a primeira página
  const firstPage = () => {
    setCurrentPage(1);
  };

  // Função para ir para a última página com registros
  const lastPage = () => {
    const totalPages = Math.ceil(filteredPosts.length / postsPerPage);
    setCurrentPage(totalPages);
  };

  // Cálculo do total de páginas
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  // Rótulo para exibir informações sobre a página atual e o total de páginas
  const pageInfo = `Página ${currentPage} de ${totalPages}`;

  /////////fim paginação

  // Filtro por categoria
  const handleCategoryChange = (category) => {
    setSelectedCategory(category === "Todas" ? null : category);
    setCurrentPage(1); // Redirecionar para a primeira página após o filtro
  };

  // Filtro por data inicial e data final lançamento
  const handleStartDateChange = (e) => {
    const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
    setStartDate(formattedDate);
    setSelectedCategory(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    setSelectedCategory(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const filterPostsByDate = (post) => {
    if (!startDate || !endDate) return true;

    const postDate = moment.utc(post.acf.data_lancamento);
    const start = moment.utc(formatDate(startDate), "DD-MM-YYYY");
    const end = moment.utc(formatDate(endDate), "DD-MM-YYYY");

    return postDate.isSameOrAfter(start) && postDate.isSameOrBefore(end);
  };

  const clearData = () => {
    setSearchTitle("");
    handleCategoryChange("");
    setStartDate("");
    setEndDate("");
    setStartDateVenci("");
    setEndDateVenci("");

    //window.location.reload();
  };

  // Fim dos filtros I

  // Filtro por data inicial e data final lançamento

  const handleStartDateChangeVenc = (e) => {
    const formattedDateVenci = moment(e.target.value).format("YYYY-MM-DD");
    setStartDateVenci(formattedDateVenci);
    setSelectedCategory(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const handleEndDateChangeVenci = (e) => {
    const formattedDateVenci = moment(e.target.value).format("YYYY-MM-DD");
    setEndDateVenci(formattedDateVenci);
    setSelectedCategory(null); // Limpar a seleção de categoria ao alterar a data
    setCurrentPage(1);
  };

  const filterPostsByDateVenci = (post) => {
    if (!startDateVenci || !endDateVenci) return true;

    const postDate = moment.utc(post.acf.data_vencimento);
    const start = moment.utc(formatDate(startDateVenci), "DD-MM-YYYY");
    const end = moment.utc(formatDate(endDateVenci), "DD-MM-YYYY");

    return postDate.isSameOrAfter(start) && postDate.isSameOrBefore(end);
  };

  // Fim dos filtros

  const totalCaixa = totalOfertaDizimo - totalDespesa;

  // Função para lidar com a alteração do número de resultados por página
  const handlePerPageChange = (event) => {
    setPerPage(parseInt(event.target.value));
  };

  return (
    <div className="bg-gray-200 dark:bg-gray-600">
      <div className="flex flex-row justify-end sm:justify-start">
        <button
          onClick={() => clientesPDF(filteredPosts)}
          className="block text-white ml-2 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-sm text-sm px-5 py-2.5 mb-4 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          type="button"
        >
          <div className="flex flex-row">
            <svg
              className="w-4 h-4 text-white dark:text-white mr-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
              />
            </svg>
            Gerar PDF
          </div>
        </button>
        <button
          className="block text-white ml-2 bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-sm text-sm px-5 py-2.5 mb-4 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
          onClick={clearData}
        >
          <div className="flex flex-row">
            <svg
              class="w-4 h-4 text-white dark:text-white mr-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
              />
            </svg>
            Limpa Filtros
          </div>
        </button>
      </div>

      <div className="bg-gray-400 h-auto py-3 px-4 dark:bg-gray-700">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-2">
          <div className="">
            <div className="w-full md:h-full md:flex ">
              <fieldset className="w-full border border-solid border-white p-2 flex flex-col justify-center items-start">
                <legend className="text-gray-900 md:text-sm px-2 dark:text-white">
                  Filtro por Categoria
                </legend>

                <select
                  className="w-full border border-gray-400 rounded-sm"
                  id="category"
                  onChange={(e) => handleCategoryChange(e.target.value)}
                  value={selectedCategory}
                >
                  <option value="">Todas</option>
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </fieldset>
            </div>
          </div>
          <div>
            <div className="w-full md:h-full md:flex">
              <fieldset className="w-full border border-solid border-white p-2 flex flex-col justify-center items-start">
                <legend className="text-gray-900 md:text-sm px-2 dark:text-white">
                  Filtro por Título
                </legend>

                <input
                  className="w-full border border-gray-400 rounded-sm"
                  type="text"
                  id="searchTitle"
                  value={searchTitle}
                  placeholder="digite aqui..."
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
              </fieldset>
            </div>
          </div>
          <div>
            <div className="w-full">
              <fieldset className="border border-solid border-white p-2">
                <legend className="text-gray-900 md:text-sm px-2 dark:text-white">
                  Filtro por Data Lançamento
                </legend>

                <div className="flex flex-col md:flex-col xl:flex-row">
                  <div className="w-full">
                    <label
                      className="mr-2  text-sm dark:text-white"
                      htmlFor="startDate"
                    >
                      Data Inicial:
                    </label>
                    <input
                      className=" w-full  border border-gray-400 rounded-sm xl:w-auto"
                      type="date"
                      id="startDate"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="mr-2 text-sm dark:text-white"
                      htmlFor="endDate"
                    >
                      Data Final:
                    </label>
                    <input
                      className=" w-full  border border-gray-400 rounded-sm xl:w-auto"
                      type="date"
                      id="endDate"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div>
            <div className="w-full">
              <fieldset className="border border-solid border-white p-2">
                <legend className="text-gray-900 md:text-sm px-2 dark:text-white">
                  Filtro por Data Vencimento
                </legend>
                <div className="flex flex-col md:flex-col xl:flex-row">
                  <div className="w-full">
                    <label
                      className="mr-2  text-sm dark:text-white"
                      htmlFor="startDateVenci"
                    >
                      Data Inicial:
                    </label>
                    <input
                      className="w-full  border border-gray-400 rounded-sm xl:w-auto"
                      type="date"
                      id="startDateVenci"
                      value={startDateVenci}
                      onChange={handleStartDateChangeVenc}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="mr-2  text-sm dark:text-white"
                      htmlFor="endDateVenci"
                    >
                      Data Final:
                    </label>
                    <input
                      className="w-full  border border-gray-400 rounded-sm xl:w-auto"
                      type="date"
                      id="endDateVenci"
                      value={endDateVenci}
                      onChange={handleEndDateChangeVenci}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-center h-full mb-5 sm:flex-col md:flex-col lg:flex-row border-solid border-b-4 border-gray-400 dark:border-gray-900">
        <div class=" w-full bg-green-500 sm:w-full md:w-full lg:w-1/5">
          <div id="display_financ" className="h-full">
            <div className=" bg-gray-600  px-6 py-4 flex flex-col h-full justify-center items-center md:text-center dark:bg-gray-800 ">
              <span className=" uppercase font-bold font-poppins text-yellow-300">
                {" "}
                total lançamentos exibidos
              </span>
              <div className=" text-left">
                <fieldset className="flex flex-col sm:flex-row md:flex-col lg:flex-col 2xl:flex-row items-center rounded border border-solid border-gray-300 p-2">
                  <legend className="px-1 text-gray-300 font-poppins text-lg">
                    {" "}
                    Total Arrecadações
                  </legend>
                  <span className=" font-normal text-lg text-white">
                    {/* &nbsp; R$ {totalOfertaDizimo.toFixed(2)} */}
                    &nbsp;{" "}
                    {totalOfertaDizimo.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                  <svg
                    class="w-6 h-6 text-green-400 dark:text-green-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13V1m0 0L1 5m4-4 4 4"
                    />
                  </svg>
                </fieldset>

                <fieldset className="flex flex-col sm:flex-row md:flex-col lg:flex-col 2xl:flex-row items-center rounded border border-solid border-gray-300 p-2">
                  <legend className="px-1 text-gray-300 font-poppins text-lg">
                    {" "}
                    Total Despesas
                  </legend>
                  <span className=" font-normal text-lg text-white">
                    &nbsp;{" "}
                    {totalDespesa.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                  <svg
                    class="w-6 h-6 ml-1 text-red-400 dark:text-red-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 1v12m0 0 4-4m-4 4L1 9"
                    />
                  </svg>
                </fieldset>

                <fieldset className="flex flex-col sm:flex-row md:flex-col lg:flex-col 2xl:flex-row items-center rounded border border-solid border-gray-300 p-2">
                  <legend className="px-1 text-gray-300 font-poppins text-lg">
                    {" "}
                    Total Caixa
                  </legend>
                  <span className=" font-normal text-lg text-white">
                    &nbsp;{" "}
                    {parseFloat(totalCaixa).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                  <div
                    className={`w-6 h-6 rounded-full ml-1   ${
                      parseFloat(totalCaixa) >= 0
                        ? "bg-green-400"
                        : "bg-red-400"
                    } dark:text-white`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 14"
                  ></div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-screen p-4 sm:h-auto sm:p-2 overflow-hidden flex flex-col justify-center items-center bg-gray-300">
          <h3 className="font-poppins text-gray-800 text-md mb-2">
            30 últimos lançamentos
          </h3>
          <ResponsiveContainer width="100%" height="80%">
            <BarChart
              data={chartData}
              style={{ background: "none", width: "100%" }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip
                formatter={(value, name, props) =>
                  ["Dízimo", "Oferta", "Despesa"].includes(name)
                    ? `R$ ${value.toFixed(2)}`
                    : value
                }
              />
              <Legend />
              <Bar dataKey="Dízimo" stackId="a" fill="#5b99fb" />
              <Bar dataKey="Oferta" stackId="a" fill="#3baea1" />
              <Bar dataKey="Despesa" stackId="a" fill="#d14e37" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        {/* ----- */}
        <div className="w-full h-screen p-4 sm:h-auto sm:p-2 overflow-hidden flex flex-col justify-center items-center bg-gray-300">
          <h3 className="font-poppins text-gray-800 text-md mb-2">
            Movimentações Mensal(ano atual)
          </h3>
          <ResponsiveContainer width="100%" height="80%">
            <BarChart
              data={chartDataMouth}
              style={{ background: "none", width: "100%" }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip
                formatter={(value, name, props) =>
                  ["Dízimo", "Oferta", "Despesa"].includes(name)
                    ? `R$ ${value.toFixed(2)}`
                    : value
                }
              />
              <Legend />
              <Bar dataKey="Dízimo" stackId="a" fill="#5b99fb" />
              <Bar dataKey="Oferta" stackId="a" fill="#3baea1" />
              <Bar dataKey="Despesa" stackId="a" fill="#d14e37" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="relative overflow-x-auto pb-20 bg-gray-200 dark:bg-gray-600">
          {/* <div className="w-100 flex justify-end mr-4">
        <form className="w-100">
            <div className="flex">
              <label
                for="countries"
                class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Nº de registros
              </label>
              <div
                className="flex justify-center items-center transititext-primary bg-gray-700  rounded-full p-2 w-5 h-5 ml-1 text-primary transition duration-150 ease-in-out "
                data-te-toggle="tooltip"
                title="Atente-se para que quanto maior o número de registros, maior será o tempo de carregamento da página!"
              >
                <span className="text-gray-300 text-md font-medium">!</span>
              </div>
            </div>
            <select
              id="perPageSelect"
              value={perPage}
              onChange={handlePerPageChange}
              className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={40}>40</option>
              <option value={50}>50</option>
              <option value={60}>60</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </select>
          </form>
        </div> */}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Nome Título
              </th>
              <th scope="col" className="px-6 py-3">
                Data Lançamento
              </th>
              <th scope="col" className="px-6 py-3">
                Valor
              </th>
              <th scope="col" className="px-6 py-3">
                Data Vencimento
              </th>
              <th scope="col" className="px-6 py-3">
                Categoria
              </th>
              <th scope="col" className="px-6 py-3">
                Observação
              </th>
              <th scope="col" className="px-6 py-3">
                Ações
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            {currentPosts.map((post) => (
              <tr key={post.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div>
                      <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                        {post.title.rendered}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-200">
                    {moment.utc(post.acf.data_lancamento).format("DD-MM-YYYY")}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-200">
                    {/* R$ {post.acf.valor_lancamento.toFixed(2)} */}
                    {post &&
                    post.acf &&
                    typeof post.acf.valor_lancamento === "number"
                      ? `R$ ${post.acf.valor_lancamento.toFixed(2)}`
                      : ""}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-200">
                    {post.acf.data_vencimento
                      ? moment(post.acf.data_vencimento).format("DD-MM-YYYY")
                      : "-"}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-200">
                    {post.acf.campo_teste}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-200">
                    {removePTags(post.content.rendered)}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center space-x-4">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-700"
                      onClick={() => handleEdit(post)}
                    >
                      Editar
                    </button>
                    <button
                      className="bg-red-500 text-white px-4 py-2 hover:bg-red-700"
                      onClick={() => handleDelete(post.id)}
                    >
                      Excluir
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* <div className="flex justify-center my-4">
          <div className="flex rounded-md my-4">
            {[
              ...Array(Math.ceil(filteredPosts.length / postsPerPage)).keys(),
            ].map((number) => (
              <button
                key={number}
                onClick={() => paginate(number + 1)}
                className={`${
                  currentPage === number + 1
                    ? "bg-blue-500 text-white"
                    : "bg-white text-gray-700"
                } py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                {number + 1}
              </button>
            ))}
          </div>
        </div> */}

        <div className="flex justify-center mt-4">
          <div className="flex rounded-md mt-4 sm:pb-36 md:pb-36 lg:pb-0">
            <button
              onClick={firstPage}
              disabled={currentPage === 1}
              className={`${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500"
              } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
            >
              Primeira
            </button>
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500"
              } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
            >
              Anterior
            </button>
            <button
              onClick={nextPage}
              disabled={
                currentPage === Math.ceil(filteredPosts.length / postsPerPage)
              }
              className={`${
                currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500"
              } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
            >
              Próxima
            </button>
            <button
              onClick={lastPage}
              disabled={
                currentPage === Math.ceil(filteredPosts.length / postsPerPage)
              }
              className={`${
                currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500"
              } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
            >
              Última
            </button>
          </div>
        </div>
        <div className="text-center mt-2 text-gray-600 dark:text-white">{pageInfo}</div>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
              Confirmação de Exclusão
            </h2>
            <p className="my-6 px-3">Deseja realmente excluir este item?</p>
            <div className="flex justify-end mt-4 w-full">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 mr-2 rounded w-full"
              >
                Excluir
              </button>
              <button
                onClick={cancelDelete}
                className="bg-gray-500 text-white px-4 py-2 rounded w-full"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditModal && (
        <EditModal
          post={editingPost}
          editedTitle={editedTitle}
          editedContent={editedContent}
          editedDataVencimento={editedDataVencimento}
          editedValorLancamento={editedValorLancamento}
          onTitleChange={setEditedTitle}
          onContentChange={setEditedContent}
          onDataVencimentoChange={setEditedDataVencimento}
          onValorLancamentoChange={setEditedValorLancamento}
          onSave={handleSave}
          onCancel={() => setShowEditModal(false)}
        />
      )}
      {loadingDelete && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p className="ml-2">Excluindo item... Aguarde!</p>
          </div>
        </div>
      )}
      {loadingEdit && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p>Salvando alterações... Aguarde!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Financeiro;
