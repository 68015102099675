import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";
import hands from "../../images/hands-up.png";
import logo from "../../images/logo-my-church.png";
import Switcher from "../../Switcher";

function Home() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserFinanceiro, setIsUserFinanceiro] = useState(false);

  const url = `${process.env.REACT_APP_BASE_URL}`;

  useEffect(() => {
    setIsLoading(true);
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const idLogin = localStorage.getItem("id");
        const subsiteName = localStorage.getItem("subsiteName");
        // console.log("id:", idLogin);
        // console.log("Token:", token);
        // console.log("subsite", subsiteName);
        const config = {
          "Content-Type": "application/json",
          headers: { Authorization: `Bearer ${token}` },
        };
        const response = await axios.get(
          `${url}/${subsiteName}/wp-json/wp/v2/users/me`,
          config
        );

        const user = response.data;
        // const isAdmin = user.roles?.includes('administrator');
        const isAdmin = user.acf && user.acf.campo_teste === "administrador";
        const isFinanceiro = user.acf && user.acf.campo_teste === "financeiro";
        localStorage.setItem("usePerfil", user.acf.campo_teste); //salva o perfil do admin pra usar no acl do menu

        // console.log("User:", user);
        // console.log("Is Admin:", isAdmin);
        if (isAdmin) {
          //   // O usuário tem o perfil de administrador, permanece na página Home
          setIsUserAdmin(true);
          navigate("/");
        } else {
          // O usuário não tem o perfil de administrador, redireciona para '/home2'
          setIsUserAdmin(false);
          navigate("/home2");
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsUserAdmin(false);
        setIsLoading(false);
        // navigate("/home2");
      }
    };

    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      fetchUserData();
    }
  }, [navigate]);
  const [newPassword, setNewPassword] = useState("");

  // const token = new URLSearchParams(window.location.search).get("token");

  const logoutHome = () => {
    // Limpar dados do usuário do localStorage
    localStorage.removeItem("userNicename");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("token");

    // Redirecionar para a página inicial
    //navigate('/');
    window.location.replace("/login");
  };

  // console.log(token)
  return (
    <div>
      {isLoading ? (
        // Modal de loading
        <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-opacity-75 bg-gray-700">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-blue-500"></div>
            <p className="mt-4 text-center">Carregando...</p>
          </div>
        </div>
      ) : (
        // Conteúdo da página
        <div
          className={`flex flex-col justify-center items-center bg-gray-300 dark:bg-gray-700 h-full overflow-hidden sm:h-screen ${
            isUserAdmin ? "" : "hidden"
          }`}
        >
          <Helmet title="Minha Igreja - Administração" />
          <div className="w-full flex justify-end items-start mt-20  mr-20 sm:-mt-20">
            <Switcher />
          </div>
          <img src={hands} className="mb-4" alt="imagem admi Minha Igreja" />
          <h1 className="text-center mb-4 text-2xl font-poppins font-bold text-gray-600 dark:text-white">
            Graça e Paz!
            <br /> Seja bem-vindo(a) ao
          </h1>
          <img
            src={logo}
            className=" max-w-sm mb-10 mt-8 px-4 mx-4"
            alt="logo Minha Igreja"
          />
          <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mx-3 sm:mx-auto">
            {isUserAdmin && (
              // Menus para o perfil de administrador
              <>
                <Link to="/create">
                  <div className="bg-white flex justify-start relative items-center h-16 py-6 px-4 max-w-full rounded-lg hover:shadow-md sm:px-6">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-2 sm:mr-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span className="text-gray-600 text-sm sm:text-base">
                      Financeiro
                    </span>
                  </div>
                </Link>
                <Link to="/fnarrc">
                  <div className="bg-white flex justify-start relative items-center h-16 py-6 px-4 max-w-full rounded-lg hover:shadow-md sm:px-6">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-2 sm:mr-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span className="text-gray-600 text-sm sm:text-base">
                      Arrecadações
                    </span>
                  </div>
                </Link>
                <Link to="/agenda">
                  <div className="bg-white flex justify-start relative items-center h-16 py-6 px-4 max-w-full rounded-lg hover:shadow-md sm:px-6">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-2 sm:mr-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                      ></path>
                    </svg>
                    <span className="text-gray-600 text-sm sm:text-base">
                      Agenda
                    </span>
                  </div>
                </Link>
                <Link to="/pregacoes">
                  <div className="bg-white flex justify-start relative items-center h-16 py-6 px-4 max-w-full rounded-lg hover:shadow-md sm:px-6">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-2 sm:mr-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                      ></path>
                    </svg>
                    <span className="text-gray-600 text-sm sm:text-base">
                      Pregações
                    </span>
                  </div>
                </Link>
                <Link to="/memlist">
                  <div className="bg-white flex justify-start relative items-center h-16 py-6 px-4 max-w-full rounded-lg hover:shadow-md sm:px-6">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-2 sm:mr-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4.333 6.764a3 3 0 1 1 3.141-5.023M2.5 16H1v-2a4 4 0 0 1 4-4m7.379-8.121a3 3 0 1 1 2.976 5M15 10a4 4 0 0 1 4 4v2h-1.761M13 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 6h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z"
                      />
                    </svg>
                    <span className="text-gray-600 text-sm sm:text-base">
                      Membros
                    </span>
                  </div>
                </Link>
                <Link to="/palavra">
                  <div className="bg-white flex justify-start relative items-center h-16 py-6 px-4 max-w-full rounded-lg hover:shadow-md sm:px-6">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-2 sm:mr-4"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      ></path>
                    </svg>
                    <span className="text-gray-600 text-sm sm:text-base">
                      Palavra
                    </span>
                  </div>
                </Link>
                <Link to="/ministerios">
                  <div className="bg-white flex justify-start relative items-center h-16 py-6 px-4 max-w-full rounded-lg hover:shadow-md sm:px-6">
                  <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-2 sm:mr-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4.333 6.764a3 3 0 1 1 3.141-5.023M2.5 16H1v-2a4 4 0 0 1 4-4m7.379-8.121a3 3 0 1 1 2.976 5M15 10a4 4 0 0 1 4 4v2h-1.761M13 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 6h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z"
                      />
                    </svg>
                    <span className="text-gray-600 text-sm sm:text-base">
                      Ministério
                    </span>
                  </div>
                </Link>
              </>
            )}
            {isUserFinanceiro && (
              // Menus para outros perfis
              <>
                <Link to="/fnarrc">
                  <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span className="text-gray-600">Arrecadações</span>
                  </div>
                </Link>
              </>
            )}

            {!isUserAdmin && !isUserFinanceiro && (
              <>
                <Link to="/agenda">
                  <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                      ></path>
                    </svg>
                    <span className="text-gray-600">Agenda</span>
                  </div>
                </Link>

                <Link to="/pregacoes">
                  <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                      ></path>
                    </svg>
                    <span className="text-gray-600">Pregações</span>
                  </div>
                </Link>
              </>
            )}
            {/* <Link to="/agenda">
              <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
                <svg
                  className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                  ></path>
                </svg>
                <span className="text-gray-600">Agenda</span>
              </div>
            </Link>

            <Link to="/pregacoes">
              <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
                <svg
                  className="w-6 h-6 text-gray-500 dark:text-gray-600 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                  ></path>
                </svg>
                <span className="text-gray-600">Pregações</span>
              </div>
            </Link>
            <Link to="/memlist">
              <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
                <svg
                  class="w-6 h-6 text-gray-500 dark:text-gray-600 mr-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4.333 6.764a3 3 0 1 1 3.141-5.023M2.5 16H1v-2a4 4 0 0 1 4-4m7.379-8.121a3 3 0 1 1 2.976 5M15 10a4 4 0 0 1 4 4v2h-1.761M13 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 6h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z"
                  />
                </svg>
                <span className="text-gray-600">Membros</span>
              </div>
            </Link> */}
          </div>
          <div className="w-full flex justify-center my-6">
            <Link to="/login" onClick={logoutHome}>
              <div className=" bg-cyan-800 flex justify-start text-white relative items-center h-16 py-6 px-6 w-full rounded-lg hover:shadow-md">
                <svg
                  className="w-6 h-6  dark:text-gray-600 mr-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 15"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="dark:text-white"
                    d="M1 7.5h11m0 0L8 3.786M12 7.5l-4 3.714M12 1h3c.53 0 1.04.196 1.414.544.375.348.586.82.586 1.313v9.286c0 .492-.21.965-.586 1.313A2.081 2.081 0 0 1 15 14h-3"
                  />
                </svg>
                <span>Sair Sistema</span>
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;

// import { useEffect } from "react";
// import { useNavigate, Link } from 'react-router-dom';
// import Helmet from 'react-helmet'

// function Home() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!localStorage.getItem('token')) {
//       navigate('/login');
//     }
//   }, [navigate]);

//   return (
//     <div className="flex justify-center items-center  bg-gray-300 h-screen overflow-hidden">
//       <Helmet title="My church - Administração" />
//       <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
//         <Link to="/create">
//           <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
//             <svg
//               className="w-6 h-6 text-gray-500 dark:text-white mr-4"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//               ></path>
//             </svg>
//             <span className="text-gray-600">Financeiro</span>
//           </div>
//         </Link>

//         <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
//           <svg
//             className="w-6 h-6 text-gray-500 dark:text-white mr-4"
//             fill="none"
//             stroke="currentColor"
//             viewBox="0 0 24 24"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
//             ></path>
//           </svg>
//           <span className="text-gray-600">Palavra</span>
//         </div>

//         <Link to="/agenda">
//           <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
//             <svg
//               className="w-6 h-6 text-gray-500 dark:text-white mr-4"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
//               ></path>
//             </svg>
//             <span className="text-gray-600">Agenda</span>
//           </div>
//         </Link>

//         <Link to="/pregacoes">
//           <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
//             <svg
//               className="w-6 h-6 text-gray-500 dark:text-white mr-4"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
//               ></path>
//             </svg>
//             <span className="text-gray-600">Pregações</span>
//           </div>
//         </Link>

//         <Link to="/pregacoes">
//           <div className=" bg-white flex justify-start relative items-center h-16 py-6 px-6  max-w-full rounded-lg hover:shadow-md">
//             <svg
//               className="w-6 h-6 text-gray-500 dark:text-white mr-4"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="m9 12 5.419 3.871A1 1 0 0 0 16 15.057V2.943a1 1 0 0 0-1.581-.814L9 6m0 6V6m0 6H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h7m-5 6h3v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-5Zm15-3a3 3 0 0 1-3 3V6a3 3 0 0 1 3 3Z"
//               ></path>
//             </svg>
//             <span className="text-gray-600">Contato</span>
//           </div>
//         </Link>
//       </div>
//     </div>
//   );
// }

// export default Home;
