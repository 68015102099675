import React, { useEffect, useState } from "react";
import axios from "axios";
import EditModalAgenda from "../EditModal/editModalAgenda";
import moment from 'moment';
import Helmet from 'react-helmet'
import EditModalPalavra from "../EditModal/editModalPalavra";

function ListaPalavra() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [editingPost, setEditingPost] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedContent, setEditedContent] = useState(""); 
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [editedDataPalavra, setEditedDataPalavra] = useState("");
  
  const [editedStatus, setEditedStatus] = useState("");

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const handleDelete = (postId) => {
    setPostIdToDelete(postId);
    setShowModal(true);
  };

  const subsiteName = localStorage.getItem("subsiteName");
  const confirmDelete = () => {
    setShowModal(false);
    setLoadingDelete(true);

    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${postIdToDelete}`,
        config
      )
      .then(() => {
        setPosts((prevPosts) =>
          prevPosts.filter((post) => post.id !== postIdToDelete)
        );
        setLoadingDelete(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingDelete(false);
      });
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setEditedTitle(post.title.rendered);
    setEditedContent(post.content.rendered);
    setEditedDataPalavra(post.acf.data_palavra);    
    setEditedStatus(post.acf.status_evento);
    setShowEditModal(true);
  };

  const handleSave = () => {
    setLoadingEdit(true);

    const editedPost = {
      title: editedTitle,
      content: editedContent,
      acf: {
        data_evento: moment(editedDataPalavra).format("YYYY-MM-DD"),        
        status_evento: editedStatus,
      },
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .put(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${editingPost.id}`,
        editedPost,
        config
      )
      .then((res) => {
        setLoadingEdit(false);
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.id === editingPost.id ? res.data : post
          )
        );
        setShowEditModal(false);
      })
      .catch((err) => {
        setLoadingEdit(false);
        console.log(err);
      });
  };

  const removePTags = (content) => {
    return content.replace(/<\/?p>/g, "");
  };

  // Controle de paginação
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://minhaigreja.tec.br/sys/${subsiteName}/wp-json/wp/v2/posts?categories=13`
  //     )
  //     .then((res) => {
  //       setPosts(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [posts]);

  useEffect(() => {
    const fetchAllPosts = async () => {
      let allPosts = [];
      let currentPage = 1;
      let totalPages = 1;
  
      // Loop até obter todas as páginas de registros
      while (currentPage <= totalPages) {
        try {
          const response = await axios.get(
            `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=palavra&page=${currentPage}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          const { data, headers } = response;
          totalPages = parseInt(headers["x-wp-totalpages"]);
          allPosts = [...allPosts, ...data];
          currentPage++;
        } catch (error) {
          console.error("Erro ao obter registros:", error);
          break; // Encerra o loop em caso de erro
        }
      }
  
      // Atualize o estado de posts com todos os registros obtidos
      setPosts(allPosts);
    };
  
    // Chame a função para buscar todos os posts ao montar o componente
    fetchAllPosts();
  }, [subsiteName, posts]);

  // Observe as mudanças aqui
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

    // Função para ir para a próxima página
const nextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};

// Função para ir para a página anterior
const prevPage = () => {
  setCurrentPage((prevPage) => prevPage - 1);
};

// Função para ir para a primeira página
const firstPage = () => {
  setCurrentPage(1);
};

// Função para ir para a última página
const lastPage = () => {
  setCurrentPage(Math.ceil(posts.length / postsPerPage));
};

// Cálculo do total de páginas
const totalPages = Math.ceil(posts.length / postsPerPage);

// Rótulo para exibir informações sobre a página atual e o total de páginas
const pageInfo = `Página ${currentPage} de ${totalPages}`;
/////////fim paginação


  // Filtro por data inicial e data final
  const handleStartDateChange = (e) => {
    const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
    setStartDate(formattedDate);    
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    setCurrentPage(1);
  };

  // const filterPostsByDate = (post) => {
  //   if (!startDate || !endDate) return true;

  //   const postDate = moment
  //     .utc(post.acf.data_evento, "DD-MM-YYYY")
  //     .startOf("day");
  //   const start = moment.utc(startDate, "DD-MM-YYYY").startOf("day");
  //   const end = moment.utc(endDate, "DD-MM-YYYY").startOf("day");

  //   return postDate.isSameOrAfter(start) && postDate.isSameOrBefore(end);
  // };

  const filterPostsByDate = (post) => {
    if (!startDate || !endDate) return true;
  
    const postDate = moment(post.acf.data_evento).startOf("day");
    const start = moment(startDate).startOf("day");
    const end = moment(endDate).startOf("day");
  
    return postDate.isSameOrAfter(start) && postDate.isSameOrBefore(end);
  };

  const clearData = () => {
    setStartDate("");
    setEndDate("");
    //window.location.reload();
  };

  // Fim dos filtros

  // if (posts.length === 0) {
  //   return <div>Sem dados para carregar...</div>;
  // }

  return (
    <div>
      <Helmet>
        <title>Minha Igreja - Palavra</title>
        <meta name="description" content="Sistema para gerenciar igrejas" />
      </Helmet>
      <div className="bg-gray-300 border-solid justify-center border-b-4 border-gray-400 flex mb-6 sm:justify-end dark:border-gray-800">
        <div className="py-2">
          <fieldset className="border border-solid border-white p-2 mr-4 flex flex-col justify-center items-start ">
            <legend className="md:text-sm">Filtro Data Palavra</legend>
            <label className="mr-2 text-sm" htmlFor="startDate">
              Data Inicial:
            </label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={handleStartDateChange}
            />
            <label className="mr-2 text-sm" htmlFor="endDate">
              Data Final:
            </label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={handleEndDateChange}
            />
            <button
              className=" bg-orange-500 text-white px-6 py-2 rounded-sm w-full ml-0 mt-2 sm:mt-auto sm:w-auto sm:ml-2 hover:bg-orange-600 md:mt-4 md:ml-0 md:w-full"
              onClick={clearData}
            >
              Limpa Filtros
            </button>
          </fieldset>
        </div>
      </div>
      <div className="relative overflow-x-auto pb-20 bg-gray-200 dark:bg-gray-600">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Título da Palavra
              </th>
              <th scope="col" className="px-6 py-3">
                Data Criação
              </th>              
              <th scope="col" className="px-6 py-3">
                Status
              </th>              
              <th scope="col" className="px-6 py-3">
                Ações
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            {posts
              .filter(filterPostsByDate)
              .slice(indexOfFirstPost, indexOfLastPost)

              .map((post) => (
                <tr key={post.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          {post.title.rendered}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      {moment.utc(post.acf.data_palavra).format("DD-MM-YYYY")}
                    </div>
                  </td> 
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      {post.acf.status_evento}
                    </div>
                  </td>                  
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-4">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-700"
                        onClick={() => handleEdit(post)}
                      >
                        Editar
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 hover:bg-red-700"
                        onClick={() => handleDelete(post.id)}
                      >
                        Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="flex justify-center mt-4">
          {/* <div className="flex rounded-md mt-4 sm:pb-36 md:pb-36 lg:pb-0">
            {[...Array(Math.ceil(posts.length / postsPerPage)).keys()].map(
              (number) => (
                <button
                  key={number}
                  onClick={() => paginate(number + 1)}
                  className={`${
                    currentPage === number + 1
                      ? "bg-blue-500 text-white"
                      : "bg-white text-gray-700"
                  } py-2 px-4 border border-gray-300 focus:outline-none`}
                >
                  {number + 1}
                </button>
              )
            )}
          </div> */}
          <div className="flex rounded-md mt-4 sm:pb-36 md:pb-36 lg:pb-0">
              <button
                onClick={firstPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Primeira
              </button>
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Anterior
              </button>
              <button
                onClick={nextPage}
                disabled={
                  currentPage === Math.ceil(posts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(posts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Próxima
              </button>
              <button
                onClick={lastPage}
                disabled={
                  currentPage === Math.ceil(posts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(posts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Última
              </button>
            </div>
          </div>
          <div className="text-center mt-2 text-gray-600 dark:text-white">{pageInfo}</div>
        
      </div>
      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
              Confirmação de Exclusão
            </h2>
            <p className="my-6 px-3">Deseja realmente excluir este item?</p>
            <div className="flex justify-end mt-4 w-full">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 mr-2 rounded w-full"
              >
                Excluir
              </button>
              <button
                onClick={cancelDelete}
                className="bg-gray-500 text-white px-4 py-2 rounded w-full"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditModal && (
        <EditModalPalavra
          post={editingPost}
          editedTitle={editedTitle}
          editedContent={editedContent}
          editedDataPalavra={editedDataPalavra}          
          editedStatus={editedStatus}
          onTitleChange={setEditedTitle}
          onContentChange={setEditedContent}
          onDataEventoChange={setEditedDataPalavra}          
          onStatus={setEditedStatus}
          onSave={handleSave}
          onCancel={() => setShowEditModal(false)}
        />
      )}
      {loadingDelete && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p className="ml-2">Excluindo item... Aguarde!</p>
          </div>
        </div>
      )}
      {loadingEdit && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p>Salvando alterações... Aguarde!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListaPalavra;