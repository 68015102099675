import React, { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import style from '../LoginPage/LoginPage.module.css';
import { useParams } from 'react-router-dom';

function CreatePost() {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [message, setMessage] = useState({ success: 'Publish', message: '' });
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    } else {
      axios
        .get('http://www.holandi.nl/wp-json/wp/v2/posts/' + id)
        .then((res) => {
          if (res === undefined) {
            return '';
          }

          setTitle(res.data.title.rendered);
          setContent(res.data.content.rendered);
        })
        .catch((err) => console.log(err));
    }
  }, [id, navigate]);

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('user_nicename');
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_display_name');
    navigate('/login');
  };

  const postData = (e) => {
    setMessage({ success: 'Process', message: 'Request is processing...' });
    e.preventDefault();
    const formdata = {
      title: title,
      content: content,
      status: 'publish',
    };
    axios
      .post('http://www.holandi.nl/wp-json/wp/v2/posts/' + id, formdata, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setMessage({ success: 'Success', message: 'Successfully edited...' });
        setContent('');
        setTitle('');
      })
      .catch((err) => {
        setMessage({ success: 'Error', message: 'Something wrong here' });
      });
  };

  return (
    <Layout>
      <div>
        {message.message && <p>{message.message}</p>}
        <h1 style={{ textAlign: 'center' }}>Edit</h1>
        <form style={{ textAlign: 'center' }} onSubmit={postData}>
          <input
            className={style.input}
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="title"
          />
          <br />
          <textarea
            rows="3"
            className={style.input}
            type="text"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="content"
          />
          <br />
          <button className={style.btn_login} type="submit">
            {message.success}
          </button>
        </form>

        <button onClick={logout}>Logout</button>
      </div>
    </Layout>
  );
}

export default CreatePost;
