import React, { useEffect, useState } from "react";
import axios from "axios";
import EditModalItensFinanceiro from "../EditModal/editModalItensFinanceiro";
import moment from "moment";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

import itensFinanceiro from "../reports/clientes/itensfinanceiro";

function ListaItens() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [editingPost, setEditingPost] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [editedCategory, setEditedCategory] = useState("");

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [filteredPosts, setFilteredPosts] = useState([]);

  const [perPage, setPerPage] = useState(10);

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const handleDelete = (postId) => {
    setPostIdToDelete(postId);
    setShowModal(true);
  };

  const subsiteName = localStorage.getItem("subsiteName");
  const confirmDelete = () => {
    setShowModal(false);
    setLoadingDelete(true);

    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${postIdToDelete}`,
        config
      )
      .then(() => {
        setPosts((prevPosts) =>
          prevPosts.filter((post) => post.id !== postIdToDelete)
        );
        setLoadingDelete(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingDelete(false);
      });
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setEditedTitle(post.title.rendered);
    setEditedContent(post.content.rendered);
    setEditedCategory(post.acf.categoria_item);
    setShowEditModal(true);
  };

  const handleSave = () => {
    setLoadingEdit(true);

    const editedPost = {
      title: editedTitle,
      content: editedContent,
      acf: { categoria_item: editedCategory },
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .put(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${editingPost.id}`,
        editedPost,
        config
      )
      .then((res) => {
        setLoadingEdit(false);
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.id === editingPost.id ? res.data : post
          )
        );
        setShowEditModal(false);
      })
      .catch((err) => {
        setLoadingEdit(false);
        console.log(err);
      });
  };

  const removePTags = (content) => {
    return content.replace(/<\/?p>/g, "");
  };

  // Controle de paginação
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  useEffect(() => {
    const fetchAllPosts = async () => {
      let allPosts = [];
      let currentPage = 1;
      let totalPages = 1;

      // Loop até obter todas as páginas de registros
      while (currentPage <= totalPages) {
        try {
          const response = await axios.get(
            `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=itens&categoria_item=Organização&per_page=${perPage}&page=${currentPage}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          const { data, headers } = response;
          totalPages = parseInt(headers["x-wp-totalpages"]);
          allPosts = [...allPosts, ...data];
          currentPage++;
        } catch (error) {
          console.error("Erro ao obter registros:", error);
          break; // Encerra o loop em caso de erro
        }
      }

      // Atualize o estado de posts com todos os registros obtidos
      setPosts(allPosts);

      //novo
      const filtered = posts.filter((post) =>
        selectedCategory ? post.acf.categoria_item === selectedCategory : true
      );

      // Defina o estado dos posts filtrados e calcule o somatório total
      setFilteredPosts(filtered);
    };

    // Chame a função para buscar todos os posts ao montar o componente
    fetchAllPosts();
  }, [perPage, selectedCategory, posts]);

  // Filtro por categoria
  const handleCategoryChange = (category) => {
    setSelectedCategory(category === "Todas" ? null : category);
    setCurrentPage(1); // Redirecionar para a primeira página após o filtro
  };

  const handleFilterChange = () => {
    setCurrentPage(1); // Redefinir a página para 1 ao aplicar qualquer filtro
  };
  // Observe as mudanças aqui

  // Calcular o índice do último post na página atual
  const indexOfLastPost = currentPage * postsPerPage;

  // Calcular o índice do primeiro post na página atual
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  // Obter os posts para a página atual
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //paginacao
  // Função para ir para a próxima página
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Função para ir para a página anterior
  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Função para ir para a primeira página
  const firstPage = () => {
    setCurrentPage(1);
  };

  // Função para ir para a última página
  const lastPage = () => {
    setCurrentPage(Math.ceil(filteredPosts.length / postsPerPage));
  };

  // Cálculo do total de páginas
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  // Rótulo para exibir informações sobre a página atual e o total de páginas
  const pageInfo = `Página ${currentPage} de ${totalPages}`;
  /////////fim paginação

  if (posts.length === 0) {
    return <div>Aguarde...</div>;
  }

  return (
    <div>
      <Helmet title="Minha Igreja - Itens Financeiro" />
      <title>Minha Igreja - Eventos</title>
      <meta name="description" content="Sistema para gerenciar igrejas" />

      <button
        onClick={() => itensFinanceiro(filteredPosts)}
        className="block text-white ml-2 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-sm text-sm px-5 py-2.5 mb-4 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
        type="button"
      >
        <div className="flex flex-row">
          <svg
            className="w-4 h-4 text-white dark:text-white mr-1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
            />
          </svg>
          Gerar PDF
        </div>
      </button>

      <div className="bg-gray-300 border-solid border-b-4 border-gray-400 flex mb-6 justify-end dark:border-gray-800">
        {/* <div className="py-2">
          <fieldset className="border border-solid border-white p-2 mr-4 flex flex-col justify-center items-start">
            <legend className="md:text-sm">Filtro Categoria</legend>
            <label className="mr-2  text-sm" htmlFor="category">
              Filtrar por categoria:
            </label>
            <select
              id="category"
              onChange={(e) => handleCategoryChange(e.target.value)}
              className="w-full"
              value={selectedCategory}
            >
              <option value="">Todas</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </fieldset>
        </div> */}
      </div>

      <div className="relative overflow-x-auto pb-20 bg-gray-200 dark:bg-gray-600">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Nome Item
              </th>
              <th scope="col" className="px-6 py-3">
                Categoria
              </th>
              <th scope="col" className="px-6 py-3">
                Obs
              </th>
              <th scope="col" className="px-6 py-3">
                Ações
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            {currentPosts.length > 0 ? (
              currentPosts.map((post) => (
                <tr key={post.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          {post.title.rendered}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          {post.acf.categoria_item}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 dark:text-gray-200">
                      {removePTags(post.content.rendered)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-4">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-700"
                        onClick={() => handleEdit(post)}
                      >
                        Editar
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 hover:bg-red-700"
                        onClick={() => handleDelete(post.id)}
                      >
                        Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center py-4">
                  Nenhum resultado encontrado.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="flex justify-center mt-4">
          <div className="flex rounded-md mt-4">
            <div className="flex rounded-md mt-4 sm:pb-36 md:pb-36 lg:pb-0">
              <button
                onClick={firstPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Primeira
              </button>
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Anterior
              </button>
              <button
                onClick={nextPage}
                disabled={
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Próxima
              </button>
              <button
                onClick={lastPage}
                disabled={
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                }
                className={`${
                  currentPage === Math.ceil(filteredPosts.length / postsPerPage)
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500"
                } text-white py-2 px-4 border border-gray-300 focus:outline-none`}
              >
                Última
              </button>
            </div>
          </div>
        </div>
        <div className="text-center mt-2 text-gray-600 dark:text-white">
          {pageInfo}
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
              Confirmação de Exclusão
            </h2>
            <p className="my-6 px-3">Deseja realmente excluir este item?</p>
            <div className="flex justify-end mt-4 w-full">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 mr-2 rounded w-full"
              >
                Excluir
              </button>
              <button
                onClick={cancelDelete}
                className="bg-gray-500 text-white px-4 py-2 rounded w-full"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditModal && (
        <EditModalItensFinanceiro
          post={editingPost}
          editedTitle={editedTitle}
          editedContent={editedContent}
          onTitleChange={setEditedTitle}
          onContentChange={setEditedContent}
          onSave={handleSave}
          onCancel={() => setShowEditModal(false)}
        />
      )}
      {loadingDelete && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p className="ml-2">Excluindo item... Aguarde!</p>
          </div>
        </div>
      )}
      {loadingEdit && (
        <div className="fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p>Salvando alterações... Aguarde!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListaItens;
