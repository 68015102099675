import React from 'react'
import Footer from '../footer/Footer'
function Layout({children}) {
    return (
        <div className=' bg-gray-200 h-screen mb-10 flex flex-col dark:bg-gray-600'>           
            {children}
            <div className=' bg-blue-500 h-10 bottom-0 fixed w-full text-center py-2'><Footer /></div>
        </div>
    )
}

export default Layout
