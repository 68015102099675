import React, { useEffect, useState, useContext } from 'react';
import Layout from "../../component/layout/Layout";
import DropdownButton from '../../component/dropdown/DropdownButton';
import hands from "../../images/hands-up.png";
import logo from "../../images/logo-my-church.png";
import {Helmet} from "react-helmet";


//import { UserContext } from '../../UserContext';

function Home2({ isAuthenticated }) {
  const userNicename = localStorage.getItem("userNicename");
  const userEmail = localStorage.getItem("userEmail");
  

  const logout = () => {
    // Limpar dados do usuário do localStorage
    localStorage.removeItem("userNicename");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("token");

    // Redirecionar para a página inicial
    //navigate('/');
    window.location.replace("/");
  };

  return (
    <Layout>  
      <Helmet>
        <title>Minha Igreja - Home Editor</title>
        <meta name="description" content="Sistema para gerenciar igrejas" />
    </Helmet>                  
      <div className="flex h-44 justify-end mr-12 mt-4">          
          <DropdownButton
            userName={userNicename}
            userEmail={userEmail}
            logout={logout}
            ></DropdownButton>
        </div>
        <div className='flex flex-col justify-center items-center'>
        <img src={hands} className="mb-4"/>
          <h1 className="text-center mb-12 text-2xl font-poppins font-bold text-gray-600 dark:text-white">Graça e Paz!<br/> Seja bem-vindo(a) ao Minha Igreja!</h1>
          <img src={logo} className=" max-w-sm mb-10 mt-8 px-1" />
        </div>      
    </Layout>
    
  );
}

export default Home2;
