import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';

const removePTags = (content) => {
  return content.replace(/<\/?p>/g, "");
};


function EditModalMinisterios (
  

  {
   post, 
  editedTitle,
  editedContent,
  editedLider,
  editedDepartamento,
  editedIntegrantes,
  onTitleChange,
  onDepartamentoChange,
  onLiderChange,
  onIntegrantesChange,
  onContentChange,
  onSave,
  onCancel
  
}) {
 
  const [selectOptions2, setSelectOptions2] = useState([]);
  const [selectOptions3, setSelectOptions3] = useState([]);
  const url = `${process.env.REACT_APP_BASE_URL}`;
  const subsiteName = localStorage.getItem("subsiteName");


  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get(
        `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=itens&categoria_item=Membro`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        const optionsFromApi2 = response.data.map((item) => ({
          value: item.title.rendered,
          label: item.title.rendered,
        }));
        const optionsFromApi3 = response.data.map((item) => ({
          value: item.title.rendered,
          label: item.title.rendered,
        }));
        setSelectOptions2(optionsFromApi2);
        setSelectOptions3(optionsFromApi3);
        
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



  const integrantesArray = typeof editedIntegrantes === 'string'
    ? editedIntegrantes.split(',').map(item => item.trim())
    : editedIntegrantes;

  const formattedIntegrantes = Array.isArray(integrantesArray)
    ? integrantesArray.map(item => ({ value: item, label: item }))
    : [];

  const combinedOptions = [
    ...new Set([...formattedIntegrantes, ...selectOptions2])
  ];

  const handleIntegrantesChange = (selectedOptions) => {
    const values = selectedOptions.map(option => option.value).join(', ');
    onIntegrantesChange(values);
  };

 
//----------------------------    
const departamentoOptions  = [
  { value: 'pastoral', label: 'Pastoral' },
  { value: 'financas', label: 'Finanças' },
  { value: 'infantil', label: 'Infantil' },
  { value: 'homens', label: 'Homens' },
  { value: 'mulheres', label: 'Mulheres' },
  { value: 'jovens', label: 'Jovens' },
  { value: 'familia', label: 'Família' },
  { value: 'oracao', label: 'Oração' },
  { value: 'intersecao', label: 'Interseção' },
  { value: 'templo', label: 'Templo' },
];

const handleDepartamentoChange = (selectedOption) => {
  onDepartamentoChange(selectedOption ? selectedOption.value : "");
};


const formattedLider = editedLider
? { value: editedLider, label: editedLider }
: null

  
  return (
    <div className="w-full text-center flex-col  p-4 fixed inset-0 z-10 flex items-center justify-center  h-screen bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded shadow flex flex-col  items-center">
        <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
          Editar Item
        </h2>

        <label htmlFor="title" className="block mb-2 text-left w-full">
          <span className="font-bold">Nome do Evento</span>
        </label>
        <input
          id="title"
          type="text"
          className="rounded-md  border-gray-300 px-4 py-2  w-full mb-4"
          value={editedTitle}
          onChange={(e) => onTitleChange(e.target.value)}
        />
        <label htmlFor="title" className="block mb-2 text-left w-full">
          <span className="font-bold">Líder Ministério</span>
        </label>
        <ReactSelect
          id="lider"
          className="w-full mb-4 rounded-md border-gray-300"
          placeholder="Selecione um líder"
          options={selectOptions3}
          onChange={(selectedOption) => onLiderChange(selectedOption ? selectedOption.value : null)}
          value={formattedLider}
        />
        
        <label htmlFor="title" className="block mb-2 text-left w-full">
          <span className="font-bold">Departamento</span>
        </label>
        <ReactSelect
          className="w-full mb-4 rounded-md border-gray-300"
          placeholder="Selecione um departamento"
          options={departamentoOptions}
          onChange={handleDepartamentoChange}
          value={departamentoOptions.find(option => option.value === editedDepartamento)}
        />
        <label htmlFor="title" className="block mb-2 text-left w-full">
          <span className="font-bold">Integrantes</span>
        </label>     
       
        
        <ReactSelect
          id="integrantes"
          className="w-full mb-4 rounded-md border-gray-300"
          placeholder="Selecione os integrantes"
          options={combinedOptions}
          isMulti
          onChange={(selectedOptions) => {
            onIntegrantesChange(selectedOptions.map(option => option.value));
          }}
          value={formattedIntegrantes}
        />
        <label htmlFor="content" className="block mb-2 text-left w-full">
          <span className="font-bold"> Observação</span>
        </label>
        <textarea
          id="content"
          className="rounded-md  border-gray-300 pr-9"
          value={removePTags(editedContent)}
          onChange={(e) => onContentChange(e.target.value)}
        ></textarea>

        <div className="flex justify-end mt-4 w-full">
          <button
            onClick={onSave}
            className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-full"
          >
            Salvar
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded w-full"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditModalMinisterios;
