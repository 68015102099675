import React, { useEffect, useState, useContext } from "react";
import Layout from "../../component/layout/Layout";

import DropdownButton from "../../component/dropdown/DropdownButton";
import GetDataPage from "../LoginPage/Login";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ModalAddMembros from "./ModalAddMembros";
import Helmet from 'react-helmet'
import ListaMembros from "./ListaMembros";

//import { UserContext } from '../../UserContext';

function Membros({ isAuthenticated }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [dataLancamento, setDataLancamento] = useState("");
  const [dataVencimento, setDataVencimento] = useState("");
  const [valor, setValor] = useState("");
  const [message, setMessage] = useState({ success: "Cadastrar", message: "" });
  const navigate = useNavigate();
  //const { user, logout } = useContext(UserContext);

  const userNicename = localStorage.getItem("userNicename");
  const userEmail = localStorage.getItem("userEmail");
  const subsiteName = localStorage.getItem("subsiteName");

  const logout = () => {
    // Limpar dados do usuário do localStorage
    localStorage.removeItem("userNicename");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("token");

    // Redirecionar para a página inicial
    //navigate('/');
    window.location.replace("/");
  };

  useEffect(() => {

    
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  // const postData = (e) => {
  //   e.preventDefault(); // Adicione esta linha para evitar o recarregamento da página

  //   setMessage({ success: "Process", message: "Request is processing..." });

  //   const formdata = {
  //     title: title,
  //     content: content,
  //     acf: {
  //       data_lancamento: dataLancamento,
  //       data_vencimento: dataVencimento,
  //       valor_lancamento: valor,
  //     },
  //     status: "publish",
  //     categories: [3],
  //     tags: [],
  //   };

  //   axios
  //     .post(
  //       `https://minhaigreja.tec.br/sys/${subsiteName}/wp-json/wp/v2/posts`,
  //       formdata,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setMessage({ success: "Success", message: "Successfully created..." });
  //       setContent("");
  //       setTitle("");
  //     })
  //     .catch((err) => {
  //       setMessage({ success: "Error", message: "Something wrong here" });
  //     });
  // };

  //controle modal

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <Helmet>
        <title>Minha Igreja - Itens Financeiro</title>
        <meta name="description" content="Sistema para gerenciar igrejas" />
     </Helmet>
      <div>
        {message.message && <p>{message.message}</p>}

        <div className="flex h-44 justify-end mr-12 mt-4">
          <DropdownButton
            userName={userNicename}
            userEmail={userEmail}
            logout={logout}
          ></DropdownButton>
        </div>

        <div className="flex flex-col justify-center mb-8 w-full sm:w-3/5 md:w-1/2 lg:w-2/12 xl:w-1/4">
          {/* <svg
            className="w-6 h-6 mt-1 ml-1 mr-4 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 11 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1.75 15.363a4.954 4.954 0 0 0 2.638 1.574c2.345.572 4.653-.434 5.155-2.247.502-1.813-1.313-3.79-3.657-4.364-2.344-.574-4.16-2.551-3.658-4.364.502-1.813 2.81-2.818 5.155-2.246A4.97 4.97 0 0 1 10 5.264M6 17.097v1.82m0-17.5v2.138"
            />
          </svg> */}
          <span className='ml-4 italic text-gray-500 mb-2 dark:text-white'>Você está em:</span>
          <h1 className=" text-xl font-bold font-poppins bg-gray-500 text-white py-4 px-4 italic mb-8">
            CADASTRO DE MEMBROS
          </h1>
        </div>

        <div className="flex justify-end  mt-4">
          <button
            onClick={openModal}
            className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 mb-4 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
          >
            Cadastrar Membro
          </button>
        </div>
        
        <ListaMembros></ListaMembros>

        {isModalOpen && (
          <div className="fixed flex justify-center items-center top-0 left-0 right-0  p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-800 bg-opacity-50">
            <div className="relative w-full md:w-2/3 2xl:w-1/3  max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={closeModal}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center">
                  <ModalAddMembros></ModalAddMembros>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Membros;
