import React, { useEffect, useState, useRef,useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import profilechurch from "../../images/holy-profile.jpg";
import ImageModal from "./ImageModal";
import DropdownButton from "../../component/dropdown/DropdownButton";
import { UserContext } from "../../UserContext";
import { IMaskInput } from "react-imask";


function CadastroIgreja() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [logoIgreja, setLogoIgreja] = useState(null);
  const [enderecoIgreja, setEnderecoIgreja] = useState("");
  const [contatoIgreja, setContatoIgreja] = useState("");
  const [fbIgreja, setFbIgreja] = useState("");
  const [intgIgreja, setIntgIgreja] = useState("");
  const [message, setMessage] = useState({ success: "Cadastrar", message: "" });
  const navigate = useNavigate();
  const navigateRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newImageId, setNewImageId] = useState(null);

  const url = `${process.env.REACT_APP_BASE_URL}`;


  // Função para lidar com a imagem
  const handleImageChange = (imageId) => {
    setNewImageId(imageId);
  };

  const [apiData, setApiData] = useState({
    title: "",
    content: "",
    endereco_igreja: "",
    contato_igreja: "",
    fb_igreja: "",
    intg_igreja: "",
    acf: {
      logo_igreja: null, // O ID da imagem será armazenado aqui
    },
  });
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    endereco_igreja: "",
    contato_igreja: "",
    fb_igreja: "",
    intg_igreja: "",
    acf: {
      logo_igreja: null, // O ID da imagem será armazenado aqui
    },
  });

  const userNicename = localStorage.getItem("userNicename");
  const userEmail = localStorage.getItem("userEmail");
  const subsiteName = localStorage.getItem("subsiteName");

  const postData = async (e) => {
    e.preventDefault();

    setMessage({
      success: "Processando. Aguarde!",
      message: "Realizando processo. Aguarde...",
    });

    // Crie um FormData para enviar a imagem
    const formData = new FormData();
    formData.append("file", logoIgreja);

    try {
      // Envie a imagem para o WordPress para fazer o upload
      const uploadResponse = await axios.post(
        `${url}/${subsiteName}/wp-json/wp/v2/media`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (uploadResponse.data.id) {
        // Se o upload for bem-sucedido, obtenha o ID da imagem e armazene-o no campo ACF
        const imageId = uploadResponse.data.id;
        const formdata = {
          title: title,
          content: content,
          acf: {
            endereco_igreja: enderecoIgreja,
            contato_igreja: contatoIgreja,
            fb_igreja:fbIgreja,
            intg_igreja:fbIgreja,
            logo_igreja: imageId, // Armazene o ID da imagem
          },
          status: "publish",
          categories: [9],
          tags: [],
        };

        // Envie os dados do formulário, incluindo o ID da imagem, para criar o post
        const createPostResponse = await axios.post(
          `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=perfil_igreja`,
          formdata,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (createPostResponse.data.id) {
          // Se o post for criado com sucesso, limpe os campos do formulário
          setMessage({
            success: "Sucesso!",
            message: "Item criado com sucesso! Aguarde...",
          });
          setContent("");
          setTitle("");
          setEnderecoIgreja("");
          setContatoIgreja("");
          setFbIgreja("");
          setIntgIgreja("");
          setLogoIgreja(null);
        } else {
          setMessage({
            success: "Ops!",
            message: "Parece que ocorreu algum erro ao criar o post :(",
          });
        }
      } else {
        setMessage({
          success: "Ops!",
          message: "Parece que ocorreu algum erro ao fazer o upload da imagem :(",
        });
      }
    } catch (error) {
      setMessage({
        success: "Ops!",
        message: "Parece que ocorreu algum erro :(",
      });
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(
        `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=perfil_igreja&acf_format=standard`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length > 0) {
          const data = res.data[0];
          const postId = data.id;
          setApiData({
            title: data.title.rendered,
            content: data.content.rendered,
            endereco_igreja: data.acf.endereco_igreja,
            contato_igreja: data.acf.contato_igreja,
            fb_igreja: data.acf.fb_igreja,
            intg_igreja: data.acf.intg_igreja,
            acf: {
              logo_igreja: data.acf.logo_igreja,
            },
          });
          setFormData({
            postId,
            title: data.title.rendered,
            content: data.content.rendered,
            endereco_igreja: data.acf.endereco_igreja,
            contato_igreja: data.acf.contato_igreja,
            fb_igreja: data.acf.fb_igreja,
            intg_igreja: data.acf.intg_igreja,
            acf: {
              logo_igreja: data.acf.logo_igreja,
            },
          });
        } else {
          console.error("Nenhum dado encontrado na resposta da API.");
        }
      })
      .catch((err) => {
        console.error("Erro ao buscar dados da API:", err);
      });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   setMessage({
  //     success: "Processando. Aguarde!",
  //     message: "Realizando processo. Aguarde...",
  //   });

  //   try {
  //     const { postId, title, content, endereco_igreja, contato_igreja, acf } = formData;

  //     // Atualize os dados do post, incluindo o campo ACF com o ID da imagem
  //     const updatedFormData = {
  //       title,
  //       content,
  //       acf: {
  //         endereco_igreja,
  //         contato_igreja,
  //         logo_igreja: newImageId || acf.logo_igreja,
          
  //       },
  //     };

  //     const updatePostResponse = await axios.put(
  //       `https://minhaigreja.tec.br/sys/${subsiteName}/wp-json/wp/v2/posts/${postId}`,
  //       updatedFormData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     if (updatePostResponse.data.id) {
  //       setMessage({
  //         success: "Sucesso!",
  //         message: "Dados atualizados com sucesso! Aguarde...",
  //       });
  //       setEditMode(false);
  //       navigate("/profileigj");
  //       window.location.reload();
  //     } else {
  //       setMessage({
  //         success: "Ops!",
  //         message: "Parece que ocorreu algum erro ao atualizar o post :(",
  //       });
  //     }
  //   } catch (error) {
  //     setMessage({
  //       success: "Ops!",
  //       message: "Parece que ocorreu algum erro :(",
  //     });
  //   }
  // };

  const updateTextFields = async (e) => {

      e.preventDefault();

    setMessage({
      success: "Processando. Aguarde!",
      message: "Realizando processo. Aguarde...",
    });

    try {
      const { postId, title, content, endereco_igreja, contato_igreja, fb_igreja, intg_igreja } = formData;
  
      const updatedTextData = {
        title,
        content,
        acf: {
          endereco_igreja,
          contato_igreja,
          fb_igreja,
          intg_igreja
        },
      };
  
      const updateTextResponse = await axios.put(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${postId}`,
        updatedTextData,
        {
          timeout: 10000, // Tempo limite de 10 segundos
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      if (updateTextResponse.data.id) {
        setMessage({
          success: "Sucesso!",
          message: "Dados de texto atualizados com sucesso! Aguarde...",
        });
        setEditMode(false);
        navigate("/profileigj");
        window.location.reload();
      } else {
        setMessage({
          success: "Ops!",
          message: "Parece que ocorreu algum erro ao atualizar os dados de texto :(",
        });
      }
    } catch (error) {
      setMessage({
        success: "Ops!",
        message: "Parece que ocorreu algum erro :(",
      });
    }
  };
 
  
  const updateImage = async (e) => {
    e.preventDefault();

    setMessage({
      success: "Processando. Aguarde!",
      message: "Realizando processo. Aguarde...",
    });
    try {
      const { postId } = formData;
  
      const updatedImageData = {
        acf: {
          logo_igreja: newImageId || null,
        },
      };
  
      const updateImageResponse = await axios.put(
        `${url}/${subsiteName}/wp-json/wp/v2/posts/${postId}`,
        updatedImageData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      if (updateImageResponse.data.id) {
        setMessage({
          success: "Sucesso!",
          message: "Imagem atualizada com sucesso! Aguarde...",
        });
        setEditMode(false);
        navigate("/profileigj");
        window.location.reload();
      } else {
        setMessage({
          success: "Ops!",
          message: "Parece que ocorreu algum erro ao atualizar a imagem :(",
        });
      }
    } catch (error) {
      setMessage({
        success: "Ops!",
        message: "Parece que ocorreu algum erro :(",
      });
    }
  };
  

  
  const removePTags = (content) => {
    return content.replace(/<\/?p>/g, "");
  };
  
  const preserveSpecialChars = (content) => {     
    return content.replace(/&#8211;/g, "-");     
  };
  
  const [tab, setTab] = useState('Tab1');

  const {
    user, // Pegue o objeto de usuário do contexto
    updateUser,
    logout,
  } = useContext(UserContext);

  const handleLogout = () => {
    logout(); // Chame a função de logout do contexto do usuário
    navigate("/"); // Navegue para a página inicial
  };
  

  return (
    <Layout>
      {/* {message.message && <p>{message.message}</p>} */}
      {/* {message.message ? (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-cyan-500"></div>
        </div>
      ) : null} */}
      {message.message ? (
        <div className="fixed flex justify-center items-center top-0 left-0 right-0  p-4 overflow-x-hidden overflow-y-auto h-screen bg-gray-800 bg-opacity-50">
          <div className="relative w-2/12  max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col justify-center items-center p-4">
              <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900 mr-2"></div>
              <p className="mt-6">{message.message}</p>
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex h-44 justify-end mr-12 mt-4">
        <DropdownButton
          userName={userNicename}
          userEmail={userEmail}
          logout={handleLogout}
        ></DropdownButton>
      </div>

      <div className="w-full flex justify-center pt-14">
        {editMode ? (
          <div className="w-full sm:w-2/3">
            <div className="">
              <button
                className={`p-4 ${
                  tab === "Tab1"
                    ? "bg-cyan-700 text-white  mr-1 "
                    : "bg-gray-300 text-gray-400 hover:bg-gray-500 hover:text-gray-700 mr-1"
                }`}
                onClick={() => setTab("Tab1")}
              >
                Dados Cadastrais
              </button>
              <button
                className={`p-4 ${
                  tab === "Tab2"
                    ? "bg-cyan-700 text-white  ml-1"
                    : "bg-gray-300 text-gray-400 hover:bg-gray-500 hover:text-gray-700 ml-1"
                }`}
                onClick={() => setTab("Tab2")}
              >
                Identidade Visual
              </button>
            </div>
            {tab === "Tab1" && (
              <div className="text-left flex flex-col justify-center p-4 bg-white my-5 rounded-md">
                {/* <h2>Conteúdo da Tab 1</h2> */}
                {/* Coloque o conteúdo da Tab 1 aqui */}
                <h1 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
                  Dados da Igreja
                </h1>

                <label>Nome da Igreja</label>
                <input
                  className="w-full mb-4 rounded-md border-gray-300"
                  type="text"
                  name="title"
                  value={preserveSpecialChars(formData.title)}
                  onChange={handleFormChange}
                  placeholder="Nome da Igreja"
                />

                <label>História da Igreja</label>
                <textarea
                  rows="3"
                  className="w-full mb-4 rounded-md border-gray-300"
                  type="text"
                  name="content"
                  value={removePTags(formData.content)}
                  onChange={handleFormChange}
                  placeholder="História da Igreja"
                />

                <label>Endereço da Igreja</label>
                <input
                  type="text"
                  name="endereco_igreja"
                  className="w-full mb-4 rounded-md border-gray-300"
                  value={formData.endereco_igreja}
                  onChange={handleFormChange}
                  placeholder="Endereço da Igreja"
                />

                <label>Contato da Igreja</label>
                {/* <input
                  type="text"
                  name="contato_igreja"
                  className="w-full mb-4 rounded-md border-gray-300"
                  value={formData.contato_igreja}
                  onChange={handleFormChange}
                  placeholder="Contato da Igreja"
                /> */}
                <IMaskInput
                  className="w-full mb-4 rounded-md border-gray-300"
                  mask="(00)00000-0000"
                  type="text"
                  name="contato_igreja"
                  value={formData.contato_igreja}
                  onChange={handleFormChange}
                  placeholder="Contato da Igreja"
                />

                <label>Facebook da Igreja</label>
                <input
                  type="text"
                  name="fb_igreja"
                  className="w-full mb-4 rounded-md border-gray-300"
                  value={formData.fb_igreja}
                  onChange={handleFormChange}
                  placeholder="Facebook da Igreja"
                />

                <label>Instagram da Igreja</label>
                <input
                  type="text"
                  name="intg_igreja"
                  className="w-full mb-4 rounded-md border-gray-300"
                  value={formData.intg_igreja}
                  onChange={handleFormChange}
                  placeholder="Instagram da Igreja"
                />

                <div className="flex">
                  <button
                    onClick={updateTextFields}
                    className="w-full mr-1 bg-blue-500 px-3 py-2 text-white hover:bg-blue-700"
                  >
                    Atualizar dados cadastrais
                  </button>
                  <button
                    onClick={() => {
                      navigate("/profileigj");
                      window.location.reload();
                    }}
                    className="w-full ml-1 bg-gray-500 px-3 py-2 text-white hover:bg-gray-700"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            )}
            {tab === "Tab2" && (
              <div className="text-left flex flex-col justify-center p-4 bg-white my-5 rounded-md">
                {/* <h2>Conteúdo da Tab 2</h2> */}
                <h1 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
                  Imagem da Igreja
                </h1>
                {/* Coloque o conteúdo da Tab 2 aqui */}
                <label> Imagem Igreja</label>
                <ImageModal
                  closeModal={() => {}}
                  setImage={handleImageChange}
                  imageId={logoIgreja}
                />
                <div className="flex">
                  <button
                    onClick={updateImage}
                    className="w-full mt-2 bg-cyan-800 px-3 py-2 text-white"
                  >
                    Atualizar Imagem
                  </button>
                  <button
                    onClick={() => {
                      navigate("/profileigj");
                      window.location.reload();
                    }}
                    className="w-full mt-2 ml-1 bg-gray-500 px-3 py-2 text-white hover:bg-gray-700"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : apiData.title ? (
          <div id="inf" className="bg-white lg:w-1/3 mb-20 mx-2 sm:mx-0">
            <div className="bg-cyan-800 py-4 text-center text-white">
              <h2>Cadastro Igreja</h2>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-center lg:items-center">
              <div className="w-full lg:pl-4">
                <div className="flex w-full items-center text-gray-600 my-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mr-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                    />
                  </svg>

                  <div className="flex flex-col justify-start">
                    <span className="font-bold">Nome Igreja</span>
                    <span>{preserveSpecialChars(apiData.title)}</span>
                  </div>
                </div>
                <hr className="w-1/3" />
                <div className="flex items-center text-gray-600 my-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mr-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                    />
                  </svg>

                  <div className="flex flex-col justify-start">
                    <span className="font-bold">História da Igreja</span>
                    <span>{removePTags(apiData.content)}</span>
                  </div>
                </div>
                <hr className="w-1/3" />
                <div className="flex items-center text-gray-600 my-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mr-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>

                  <div className="flex flex-col justify-start">
                    <span className="font-bold">Endereço da Igreja</span>
                    <span>{apiData.endereco_igreja}</span>
                  </div>
                </div>
                <hr className="w-1/3" />
                <div className="flex items-center text-gray-600 my-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mr-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                    />
                  </svg>

                  <div className="flex flex-col justify-start">
                    <span className="font-bold">Contato da Igreja</span>
                    <span>{apiData.contato_igreja}</span>
                  </div>
                </div>

                <hr className="w-1/3" />
                <div className="flex items-center text-gray-600 my-4">
                  <svg
                    className="w-6 h-6 mr-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 8 19"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <div className="flex flex-col justify-start">
                    <span className="font-bold">Facebook da Igreja</span>
                    <span>{apiData.fb_igreja}</span>
                  </div>
                </div>

                <hr className="w-1/3" />
                <div className="flex items-center text-gray-600 my-4">
                  <svg
                    className="w-6 h-6 mr-3"                    
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <rect x="4" y="4" width="16" height="16" rx="4" />{" "}
                    <circle cx="12" cy="12" r="3" />{" "}
                    <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                  </svg>

                  <div className="flex flex-col justify-start">
                    <span className="font-bold">Instagram da Igreja</span>
                    <span>{apiData.intg_igreja}</span>
                  </div>
                </div>

                <hr className="w-1/3" />
                <div className="flex items-center text-gray-600 my-4">
                  <div className="flex flex-col justify-start">
                    <span className="font-bold">Logotipo da Igreja</span>
                    <img
                      src={apiData.acf.logo_igreja}
                      className="w-20 h-10"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end">
                <img src={profilechurch} alt="Igreja" className="w-full" />
              </div>
            </div>
            <div className="w-full">
              <button
                className="bg-blue-500 w-full px-4 py-3 rounded-sm text-white hover:bg-blue-900"
                onClick={handleEdit}
              >
                Editar
              </button>
            </div>
          </div>
        ) : (
          <form
            className="w-1/2 text-left flex flex-col justify-center p-4 dark:bg-white dark:my-5 dark:rounded-md"
            onSubmit={postData}
          >
            <h1 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
              Dados da Igreja
            </h1>

            <label>Nome da Igreja</label>
            <input
              className="w-full mb-4 rounded-md border-gray-300"
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Nome da Igreja"
            />

            <label>História da Igreja</label>
            <textarea
              rows="3"
              className="w-full mb-4 rounded-md border-gray-300"
              type="text"
              name="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="História da Igreja"
            />

            <label>Endereço da Igreja</label>
            <input
              type="text"
              name="endereco_igreja"
              className="w-full mb-4 rounded-md border-gray-300"
              value={enderecoIgreja}
              onChange={(e) => setEnderecoIgreja(e.target.value)}
              placeholder="Endereço da Igreja"
            />

            <label>Contato da Igreja</label>
            <input
              type="text"
              name="contato_igreja"
              className="w-full mb-4 rounded-md border-gray-300"
              value={contatoIgreja}
              onChange={(e) => setContatoIgreja(e.target.value)}
              placeholder="Contato da Igreja"
            />

            <label>Logo da Igreja (PNG ou JPEG, até 5MB)</label>
            <input
              type="file"
              name="logo_igreja"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => setLogoIgreja(e.target.files[0])}
            />

            <button
              type="submit"
              className="w-full m-0 focus:outline-none text-white bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 font-medium text-base py-2.5 mb-4 dark:bg-cyan-600 dark:hover-bg-cyan-700 dark:focus-ring-cyan-800"
            >
              Cadastrar
            </button>
          </form>
        )}
      </div>
    </Layout>
  );
}

export default CadastroIgreja;
