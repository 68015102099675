import React from 'react'

function Footer() {
    return (
        <div className='text-white' >
            Minha Igreja&copy; 1.0 - Todos os direitos reservados.
        </div>
    )
}

export default Footer
