import React from "react";
import { useState } from 'react';
import moment from "moment";

const removePTags = (content) => {
  return content.replace(/<\/?p>/g, "");
};


function EditModalAgendaPrecacoes (

  {
  post,
  editedTitle,
  editedContent,  
  editedDataPregacao,  
  editedUrlPregacao,  
  onTitleChange, 
  onContentChange,
  onDataPregacaoChange,
  onUrlChange,
  onSave,
  onCancel 
  
}) {

  const [formattedDataPregacao, setFormattedDataPregacao] = useState(
    editedDataPregacao ? moment(editedDataPregacao).format("DD-MM-YYYY") : ""
  );

  // Função para interpretar a data digitada pelo usuário e atualizar o estado com o formato "YYYY-MM-DD"
  const handleDataPregacaoChange = (value) => {
    const digitsOnly = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    const formattedDate = moment(digitsOnly, "DDMMYYYY").format("YYYY-MM-DD");
    setFormattedDataPregacao(value);
    onDataPregacaoChange(formattedDate);
  };
 
  
  return (
    <div className="w-full text-center flex-col  p-4 fixed inset-0 z-10 flex items-center justify-center  h-screen bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded shadow flex flex-col  items-center">
        <h2 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
          Editar Item
        </h2>

        <label htmlFor="title" className="block mb-2 text-left w-full">
          <span className="font-bold">Nome do Título</span>
        </label>
        <input
          id="title"
          type="text"
          className="rounded-md  border-gray-300 px-4 py-2 w-full mb-4"
          value={editedTitle}
          onChange={(e) => onTitleChange(e.target.value)}
        />
        <label htmlFor="title" className="block mb-2 text-left w-full">
          <span className="font-bold">Data Vencimento</span>
        </label>
        <input
          id="data_pregacao"
          type="text"
          className="rounded-md  border-gray-300 px-4 py-2  w-full mb-4"
          value={formattedDataPregacao}
          onChange={(e) => handleDataPregacaoChange(e.target.value)}
        />

        <label htmlFor="content" className="block mb-2 text-left w-full">
          <span className="font-bold"> URL Pregação</span>
        </label>
        <textarea
          id="content"
          cols="20" rows="3"
          className="rounded-md  border-gray-300 pr-9"
          value={editedUrlPregacao}
          onChange={(e) => onUrlChange(e.target.value)}
        ></textarea>

        <label htmlFor="content" className="block mb-2 text-left w-full">
          <span className="font-bold"> Observação</span>
        </label>
        <textarea
          id="content"
          className="rounded-md  border-gray-300 pr-9"
          value={removePTags(editedContent)}
          onChange={(e) => onContentChange(e.target.value)}
        ></textarea>

        <div className="flex justify-end mt-4 w-full">
          <button
            onClick={onSave}
            className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-full"
          >
            Salvar
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded w-full"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditModalAgendaPrecacoes;
