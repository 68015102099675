import React, { useEffect, useState,  useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IMaskInput } from "react-imask";

function ModalAddItens() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [data_nascimento, setDataNascimento] = useState("");
  const [data_afiliacao, setDataAfiliacao] = useState((getFormattedDate()));
  const [whatsapp, setWhatsApp] = useState("");
 
  const [message, setMessage] = useState({ success: "Cadastrar", message: "" });
  const navigate = useNavigate();
  const navigateRef = useRef(null);
  const [campoCat, setCampoCat] = useState("");

  const url = `${process.env.REACT_APP_BASE_URL}`;

  const userNicename = localStorage.getItem("userNicename");
  const userEmail = localStorage.getItem("userEmail");
  const subsiteName = localStorage.getItem("subsiteName")

  const logout = () => {
    // Limpar dados do usuário do localStorage
    localStorage.removeItem("userNicename");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("token");

    // Redirecionar para a página inicial
    //navigate('/');
    window.location.replace("/");
  };

  function getFormattedDate() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = String(now.getFullYear());
    return `${day}/${month}/${year}`;
  }


  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
  }, [navigate]);

  const postData = (e) => {
    e.preventDefault(); // Adicione esta linha para evitar o recarregamento da página

    setMessage({ success: "Processando. Aguarde!", message: "Realizando processo. Aguarde..." });

    const formdata = {
      title: title,
      content: content,  
      acf:{
        categoria_item: "Organização",
        // data_nascimento: data_nascimento,
        // data_afiliacao: data_afiliacao,
        // endereco: endereco,
        // bairro: bairro,
        // cidade: cidade,
        // whatsapp: whatsapp,
      },
      status: "publish",
      categoria: "itens",
      //categories: [10],
      tags: [],
    };

    axios
      .post(
        `${url}/${subsiteName}/wp-json/wp/v2/posts?categoria=itens`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setMessage({ success: "Sucesso!", message: "Item criado com sucesso!" });
        setContent("");
        setTitle("");           
        // setTimeout(() => {
        //   window.location.reload(); // Atualizar a página após 3 segundos
        // }, 1000);
      })
      .catch((err) => {
        setMessage({ success: "Ops!", message: "Parece que ocorreu algum erro :(" });
      });
     
  };

  //controle modal

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <div>
      {message.message && <p>{message.message}</p>}

      <form
        className="w-full text-left flex flex-col justify-center p-4 dark:bg-white dark:my-5 dark:rounded-md"
        onSubmit={postData}
        ref={navigateRef}
      >
        <h1 className="mb-4 text-lg bg-cyan-800 w-full py-4 text-white rounded-tl-md rounded-tr-md text-center">
          Cadastro de Item
        </h1>

        {/* <label>Categoria do Item</label>
        <select
          className="w-full mb-4 rounded-md  border-gray-300"
          value={campoCat}
          onChange={(e) => setCampoCat(e.target.value)}
        >
          <option value="">Selecione uma opção</option>
          <option value="Membro">Membro</option>
          <option value="Organização">Organização</option>
        </select>

        <label>
          {campoCat === "Membro" ? "nome do membro" : "nome do item"}
        </label>
        <input
          className="w-full mb-4 rounded-md  border-gray-300"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={
            campoCat === "Membro" ? "Nome do Membro" : "Nome do Item"
          }
        /> */}
        <label>
          Nome do item
        </label>
        <input
          className="w-full mb-4 rounded-md  border-gray-300"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Nome do Item"         
        /> 

        {/* Campo de Endereço, mostrado apenas quando a categoria for "Membro" */}
        {/* {campoCat === "Membro" && (
          <>
            <label>Endereço</label>
            <input
              className="w-full mb-4 rounded-md border-gray-300"
              type="text"
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
              placeholder="rua, número, complemento"
            />
            <label>Endereço</label>
            <input
              className="w-full mb-4 rounded-md border-gray-300"
              type="text"
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
              placeholder="bairro"
            />
             <label>Cidade</label>
            <input
              className="w-full mb-4 rounded-md border-gray-300"
              type="text"
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
              placeholder="cidade"
            />
            <label>Data de Nascimento</label>
            <input
              className="w-full mb-4 rounded-md border-gray-300"
              type="text"
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
              placeholder="data de nascimento"
            /> 
            <IMaskInput 
              className="w-full mb-4 rounded-md py-2 px-3 border-gray-300 border"
              mask="00/00/0000" 
              value={data_nascimento}
              onChange={(e) => setDataNascimento(e.target.value)}
              placeholder="data de nascimento - 00/00/0000" 
            />
            <label>Data de Afiliação</label>            
            <input 
              className="w-full mb-4 rounded-md py-2 px-3 border-gray-300 border bg-gray-200"
              disabled
              value={data_afiliacao}
              onChange={(e) => setDataAfiliacao(e.target.value)}
              placeholder="data de afialição" 
            />
            <label>WhatsApp</label>
            <IMaskInput 
              className="w-full mb-4 rounded-md border-gray-300"
              mask="(00)00000-0000" 
              type="text"
              value={whatsapp}
              onChange={(e) => setWhatsApp(e.target.value)}
              placeholder="whatsapp"
            
            />            
          </>
        )} */}

        <label>Observação</label>
        <textarea
          rows="3"
          className="w-full mb-4 rounded-md  border-gray-300"
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="observação"
        />

        <button
          type="submit"
          class=" w-full m-0  focus:outline-none text-white bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 font-medium text-base py-2.5 mb-4 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
        >
          {message.success}
        </button>
      </form>
    </div>
  );
}

export default ModalAddItens;
