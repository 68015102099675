import { useEffect, useState } from "react";
import Layout from "../../component/layout/Layout";

import { Link } from "react-router-dom";
import axios from "axios";

function GetDataPage() {
  const [posts, setPost] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      axios
        .get("https://igreja.natuspixel.com.br/svr/wp-json/wp/v2/posts")
        .then((res) => {
          setPost(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => (mounted = false);
  }, []);

  if (posts.length === 0) {
    return <div>Carregando dados...</div>;
  }

  console.log(posts);

  return (
    <Layout>
      <div className="p-10">
	  <Link to="/create">
        <button
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Criar Lançamento
        </button>
      </Link>     
      {/* <div style={{ fontSize: '20px', padding: '10px', marginBottom: '10px' }}>
				{posts.length > 0 ? (
					posts.map((post) => {
						if (post === undefined) {
							return '';
						}
						return (
							<div className={style.post} key={post.id}>
								<Link to={`/post/${post.id}`}>
									<p>
										Título : <div dangerouslySetInnerHTML={{ __html: post.title.rendered }} />{' '}
									</p>

									<p>
										Data Lançamento : <div dangerouslySetInnerHTML={{ __html: post.acf.data_lancamento}} />
									</p>

									<p>
										Data Lançamento : <div dangerouslySetInnerHTML={{ __html: 'R$ ' + post.acf.valor_lancamento}} />
									</p>

									
								</Link>
							</div>
						);
					})
				) : (
					<div>Loading...</div>
				)}
			</div> */}

      <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Nome Título
              </th>
              <th scope="col" class="px-6 py-3">
                Data Lançamento
              </th>
              <th scope="col" class="px-6 py-3">
                Valor
              </th>
              <th scope="col" class="px-6 py-3">
                Data Vencimento
              </th>
			  <th scope="col" class="px-6 py-3">
                Observação
              </th>
            </tr>
          </thead>
          <tbody>
            {posts.length > 0 ? (
              posts.map((post) => {
                if (post === undefined) {
                  return "";
                }
                return (
                  <tr
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    key={post.id}
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {post.title.rendered}
                    </th>
                    <td class="px-6 py-4">
                      {post.acf.data_lancamento}
                    </td>
                    <td class="px-6 py-4">
                       R$ {post.acf.valor_lancamento}                      
                    </td>
                    <td class="px-6 py-4">
                      { post.acf.data_vencimento}
                    </td>
					<td class="px-6 py-4">
                      { post.acf.observacao}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div>Loading...</div>
            )}
          </tbody>
        </table>
      </div>
	  </div>
    </Layout>
  );
}

export default GetDataPage;
