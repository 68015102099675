/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from 'react';
import ze from '../../images/ze.jpg';
import { Link } from 'react-router-dom';

function DropdownButton(props) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);

  const subsiteName = localStorage.getItem("subsiteName");

  useEffect(() => {
    function handleClickOutside(event) {
      if (isOpen && !event.target.closest('#dropdownAvatarNameButton')) {
        setIsOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

    localStorage.getItem("usePerfil");
  }, [isOpen]);

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <button
        ref={buttonRef}
        id="dropdownAvatarNameButton"
        data-dropdown-toggle="dropdownAvatarName"
        className="flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-gray-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white"
        type="button"
        onClick={toggleDropdown}
      >
        <span className="sr-only">Open user menu</span>
        {/* <img
          className="w-8 h-8 mr-2 rounded-full"
          src={ze}
          alt="user photo"
        /> */}
        <svg
          className="w-7 h-7 mr-2 border border-gray-700 p-1 rounded-full text-gray-800 dark:text-white dark:border-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 14 18"
        >
          <path d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
        </svg>
        Meu Perfil
        <svg
          className="w-4 h-4 mx-1.5"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div
          id="dropdownAvatarName"
          className="z-10 absolute mt-2 right-5 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
        >
          <div className="p-1 text-sm text-gray-900 dark:text-white">
            <br />
            <div className="font-medium text-gray-500 flex items-center">
              <svg
                className="w-3 h-3 dark:text-white mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 14 18"
              >
                <path d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
              </svg>
              <div className="dark:text-white">{props.userName}</div>
            </div>
            <div className="text-gray-500 flex items-center mt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mx-1 dark:text-white"
              >
                <path
                  strokeLinecap="round"
                  d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                />
              </svg>

              <div className="truncate dark:text-white ">{props.userEmail}</div>
            </div>
            {localStorage.getItem("usePerfil") === "administrador" && (
              <>
                <a href="/profileigj">
                  <div className="text-gray-800 font-semibold flex items-center mt-3 hover:bg-cyan-600 py-2 dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3 h-3 dark:text-white mx-1 dark:hover:text-cyan-900"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                      />
                    </svg>
                    Perfil
                  </div>
                </a>
                <a
                  href="https://minhaigreja.tec.br/doc_mi/guia_de_uso_minha_igreja.pdf"
                  target="_blank"
                >
                  <div className="text-gray-800 font-semibold flex items-center mt-1 hover:bg-cyan-600  py-2 dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3 h-3 dark:text-white mx-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                      />
                    </svg>
                    Documentação
                  </div>
                </a>
                <a href={`https://sites.minhaigreja.tec.br/${subsiteName}`} target="_blank">
                  <div className="text-gray-800 font-semibold flex items-center mt-1 hover:bg-cyan-600  py-2 dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-3 h-3 dark:text-white mx-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                      />
                    </svg>
                    Meu Site
                  </div>
                </a>
              </>
            )}
          </div>
          {/* <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownAvatarNameButton"
          >
            <li>
              <p>{props.userName}</p>
            </li>
            <li>
              <p>asdasd</p>
            </li>
            <li>
              <p>asdasd</p>
            </li>
          </ul> */}

          <div className="py-2">
            <Link
              className="block px-4 py-2 text-sm text-gray-700   dark:hover:text-white"
              onClick={props.logout}
            >
              <div className="flex items-center w-full justify-center text-white bg-cyan-700 px-3 py-2 rounded hover:bg-cyan-800">
                Sair
                <svg
                  className="w-4 h-4 text-white ml-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 15"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 7.5h11m0 0L8 3.786M12 7.5l-4 3.714M12 1h3c.53 0 1.04.196 1.414.544.375.348.586.82.586 1.313v9.286c0 .492-.21.965-.586 1.313A2.081 2.081 0 0 1 15 14h-3"
                  />
                </svg>
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownButton;
