import React, { useState, useContext } from "react";
import axios from "axios";
import logo from "../../images/logo-minha-igreja-login.png";
import lgScreen from "../../images/lg-screen.jpg";

import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";

function Login() {
  const [subsiteName, setSubsiteName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { updateUser } = useContext(UserContext);
  const navigate = useNavigate();
  const url = `${process.env.REACT_APP_BASE_URL}`;

  const handleLogin = (e) => {
   
    e.preventDefault();

     // Verificar se os campos de nome do site, nome de usuário e senha estão vazios
  if (!subsiteName ||!username || !password) {
    setError("Por favor, preencha todos os campos.");
    return;
  }
    setLoading(true);
    setError("");
    

    // Construa a URL do subsite com base no nome do subsite inserido pelo usuário
    const subsiteUrl = `${url}/${subsiteName}/`;

    const loginData = {
      username: username,
      password: password,
    };

    axios
      .post(`${subsiteUrl}wp-json/jwt-auth/v1/token`, loginData)
      .then((res) => {
        const userData = {
          id: res.data.data.id,
          email: res.data.data.email,
          nicename: res.data.data.nicename,
          firstName: res.data.data.firstName,
          lastName: res.data.data.lastName,
          displayName: res.data.data.displayName,
        };
        updateUser(userData);
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("id", res.data.data.id);
        localStorage.setItem("userNicename", res.data.data.nicename);
        localStorage.setItem("userEmail", res.data.data.email);
        localStorage.setItem("subsiteName", subsiteName);

        navigate("/");
      })
      .catch((err) => {
        setError("Login ou senha inválidos!");
      })
      .finally(() => {
        setLoading(false);
      });

      // Gerar o token
      
  };

  return (
    <div>
      <Helmet>
        <title>Minha Igreja - Login Usuário</title>
        <meta name="description" content="Sistema para gerenciar igrejas" />
      </Helmet>

      <section className="flex flex-col md:flex-row h-screen items-center">
        <div className="bg-blue-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
          <img src={lgScreen} className="w-full h-full object-cover" />
        </div>

        <div
          className="bg-gray-300 w-full md:max-w-md lg:max-w-full md:mx-auto  md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
          flex items-center justify-center"
        >
          <div className="w-full h-100">
            <form
              className=" text-center flex flex-col justify-center items-center "
              onSubmit={handleLogin}
            >
              <div className="bg-cyan-900 border-b-8 border-cyan-950 rounded-3xl flex flex-col justify-center items-center p-4">
                <img className=" w-20 mb-6" src={logo} alt="logo" />
                <h1 className="text-white text-2xl font-bold font-rubik uppercase mb-6 px-4">
                  Painel Administrativo
                </h1>
                <input
                  className="w-60 mb-6 border-solid border-1 border-gray-300 rounded-md"
                  type="text"
                  placeholder="Nome do site"
                  value={subsiteName}
                  onChange={(e) => setSubsiteName(e.target.value)}
                />
                <input
                  className="w-60 mb-6 border-solid border-1 border-gray-300 rounded-md"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="usuário"
                />

                <input
                  className="w-60 mb-6 border-solid border-1 border-gray-300 rounded-md"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="senha"
                />

                {loading ? (
                  // md:inset-0 h-[calc(100%-1rem)] max-
                  <div className="fixed flex justify-center items-center top-0 left-0 right-0  p-4 overflow-x-hidden overflow-y-auto h-screen bg-gray-800 bg-opacity-50">
                    <div className="relative w-6/12  max-h-full sm:w-2/12">
                      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col justify-center items-center p-4">
                        <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900 mr-2"></div>
                        <p className="mt-6">Validando...Aguarde!</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="w-60 m-0 focus:outline-none text-white bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 font-medium rounded-lg text-lg py-2.5  my-4 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                    >
                      Acessar
                    </button>
                    <div className="text-yellow-200  hover:text-yellow-400 my-4">
                      <a
                        href="/recpasw"
                        className="flex justify-center items-center"
                      >
                        
                        <span className="m-0 p-0">Esqueci minha senha!</span>
                        <svg
                          className="w-4 h-4 ml-2 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 16 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M11.5 8V4.5a3.5 3.5 0 1 0-7 0V8M8 12v3M2 8h12a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z"
                          />
                        </svg>
                      </a>
                    </div>
                  </>
                )}
                {error && <p className="text-white">{error}</p>}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;