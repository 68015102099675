import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Inicialmente, tenta buscar os dados do usuário do localStorage
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : {
      id: null,
      email: '',
      nicename: '',
      firstName: '',
      lastName: '',
      displayName: '',
    };
  });

  useEffect(() => {
    // Quando o estado do usuário for atualizado, também atualize o localStorage
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  const updateUser = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    // Limpe os dados do usuário e remova do localStorage
    setUser({
      id: null,
      email: '',
      nicename: '',
      firstName: '',
      lastName: '',
      displayName: '',
    });
    localStorage.removeItem('user');
  };

  return (
    <UserContext.Provider value={{ user, updateUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};
